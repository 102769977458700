<template>
  <b-row>
    <div class="form-mobile-wrapper">
      <template v-if="isLoading">
        <div class="spinner-container-main">
          <b-spinner></b-spinner>
        </div>
      </template>
      <template v-else>
        <template v-if="!isAppNotExist">
          <div class="form-mobile-wrapper-center">
            <div class="logo-container">
              <img alt="Logo" class="logo" src="@/assets/logo.png" />
            </div>
            <div class="form-container">
              <MobileForm
                :form-data="formData"
                :is-loading="isLoadingStep"
                :on-submit="sendForm"
                ref="MobileFormComponent"
              />
              <div v-if="isLoadingStep" class="spinner-container mt-3 mb-3 p-2">
                <b-spinner></b-spinner>
              </div>
              <div class="form-btns-mobile-container">
                <div class="m-3">
                  <div>
                    <button class="btn-main" @click="submitForm($event)" :disabled="isLoadingStep">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="invalid-container">
            <div class="flex-center">
              <img alt="Logo" class="p-3" src="@/assets/logo.png" />
              <h3 class="text-center">Your Application has already been Submitted.</h3>
              <h3 class="text-center">Thank You!</h3>
              <p class="p-4 text-center">
                Our team is now reviewing it and should be back to you in a few days for your approval and to proceed.
              </p>
            </div>
          </div>
        </template>
      </template>
    </div>
  </b-row>
</template>
<script>
// API
import FormService from '@/services/form-service/form.service';
import { toast } from 'vue3-toastify';
// Components
import MobileForm from '@/components/form-layouts/MobileForm/MobileForm.vue';
import './MobileFormView.scss';
/* eslint-disable */
export default {
  name: 'MobileFormView',
  components: {
    MobileForm
  },
  data() {
    return {
      isLoading: true,
      isLoadingStep: false,
      isAppNotExist: true,
      formId: null,
      formData: {
        yourName: '',
        yourDateOfBirth: '',
        yourMaritalStatus: 'Single',
        nameAndAddressOfLastUKEmployed: '',
        approxDateLeaveUK: '',
        youWorkingImmediatelyBeforeLeavingUK: true,
        nameAndAddressOfFirstWorkAfterUK: '',
        approxDateStopFirstWorking: '',
        youDone: '',
        nationalInsuranceNumber: '',
        requestPRSIorSocialInsStatement: true,
        yearsInTheUK: 1
      }
    };
  },
  mounted() {
    console.log('Transaction ID', this.$route.params.id);
    if (this.$route?.params?.id) {
      this.formId = this.$route?.params?.id;
      this.initForm();
    }
  },
  methods: {
    async initForm() {
      this.showSpinner();
      try {
        const { status, formData } = await FormService.getMobileFormData(this.formId);
        console.log('Init Mobile Form', status, formData);
        if (status === 'Success') {
          this.isAppNotExist = false;
          this.formData = this.formatMobileFormData(formData);
        } else {
          toast.warning(status ?? 'Oops, Something Went Wrong');
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message ?? 'Oops, Something Went Wrong');
      } finally {
        this.hideSpinner();
      }
    },
    formatMobileFormData(formData) {
      return {
        yourName: formData?.yourName ?? '',
        yourDateOfBirth: formData?.yourDateOfBirth ?? '',
        yourMaritalStatus: formData?.yourMaritalStatus ?? 'Single',
        nameAndAddressOfLastUKEmployed: formData?.nameAndAddressOfLastUKEmployed ?? '',
        approxDateLeaveUK: formData?.approxDateLeaveUK ?? '',
        youWorkingImmediatelyBeforeLeavingUK: formData?.youWorkingImmediatelyBeforeLeavingUK ?? true,
        nameAndAddressOfFirstWorkAfterUK: formData?.nameAndAddressOfFirstWorkAfterUK ?? '',
        approxDateStopFirstWorking: formData?.approxDateStopFirstWorking ?? '',
        youDone: formData?.youDone ?? '',
        nationalInsuranceNumber: formData?.nationalInsuranceNumber ?? '',
        requestPRSIorSocialInsStatement: formData?.requestPRSIorSocialInsStatement ?? true,
        yearsInTheUK: formData?.yearsInTheUK ?? 1
      };
    },
    async submitForm(event) {
      event.preventDefault();
      event.stopPropagation();
      try {
        await this.$refs.MobileFormComponent.submitForm();
      } catch (error) {
        console.log(error);
        toast.error(error.message ?? 'Oops, Something Went Wrong');
      }
    },
    async sendForm() {
      this.showStepSpinner();
      try {
        const { status, isComplete } = await FormService.sendMobileFormData(this.formData, this.formId);
        console.log('Submit Mobile Form', status, isComplete, this.formData);
        if (status === 'Success') {
          if (isComplete === true) {
            window.open('https://xtrapension.com/thank-you', '_self');
          }
        } else {
          toast.warning(status ?? 'Oops, Something Went Wrong');
        }
      } catch (error) {
        toast.error(error.message ?? 'Oops, Something Went Wrong');
        console.log(error);
      } finally {
        this.hideStepSpinner();
      }
    },
    showSpinner() {
      this.isLoading = true;
    },
    hideSpinner() {
      this.isLoading = false;
    },
    showStepSpinner() {
      this.isLoadingStep = true;
    },
    hideStepSpinner() {
      this.isLoadingStep = false;
    }
  }
};
</script>
