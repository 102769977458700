<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-form class="p-3" @submit.prevent="submitForm">
    <b-row class="text-center mb-5">
      <b-col>
        <h1>We Need Some Answers Please!</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"><label>1) What's Your Name? (+ incl any Maiden Name)</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model.trim="formData.yourName"
            @input="setTouched('yourName')"
            :class="v$.formData.yourName.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            placeholder="EG: Mary Burns (nee Kennedy)"
          ></b-form-input>
          <div v-for="error of v$.formData.yourName.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6"><label>2) Whats Your Date of Birth?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model="formData.yourDateOfBirth"
            type="date"
            @input="setTouched('yourDateOfBirth')"
            :class="v$.formData.yourDateOfBirth.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            :max="todayDate"
            min="1900-01-01"
          ></b-form-input>
          <div v-for="error of v$.formData.yourDateOfBirth.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6">
        <label>3) What's Your Marital Status?</label>
      </b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-select
            v-model="formData.yourMaritalStatus"
            :options="maritalStatusOptions"
            @input="setTouched('yourMaritalStatus')"
            :class="v$.formData.yourMaritalStatus.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
          ></b-form-select>
          <div v-for="error of v$.formData.yourMaritalStatus.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6"><label>4) Name & Address of Last UK Employer?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model.trim="formData.nameAndAddressOfLastUKEmployed"
            @input="setTouched('nameAndAddressOfLastUKEmployed')"
            :class="v$.formData.nameAndAddressOfLastUKEmployed.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            placeholder="EG: Murphy & Sons, Highgate, London"
          ></b-form-input>
          <div
            v-for="error of v$.formData.nameAndAddressOfLastUKEmployed.$errors"
            class="invalid-feedback"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6"><label>5) Approx When Did You Leave UK?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model="formData.approxDateLeaveUK"
            type="date"
            @input="setTouched('approxDateLeaveUK')"
            :class="v$.formData.approxDateLeaveUK.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            :max="todayDate"
            min="1900-01-01"
          ></b-form-input>
          <div v-for="error of v$.formData.approxDateLeaveUK.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6">
        <label>6) Did You Work IMMEDIATELY before Leaving UK?</label>
      </b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-select
            v-model="formData.youWorkingImmediatelyBeforeLeavingUK"
            :options="yesNoOptions"
            @input="setTouched('youWorkingImmediatelyBeforeLeavingUK')"
            :class="v$.formData.youWorkingImmediatelyBeforeLeavingUK.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
          ></b-form-select>
          <div
            v-for="error of v$.formData.youWorkingImmediatelyBeforeLeavingUK.$errors"
            class="invalid-feedback"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6"><label>7) Name & Address of 1st Work AFTER UK?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model.trim="formData.nameAndAddressOfFirstWorkAfterUK"
            @input="setTouched('nameAndAddressOfFirstWorkAfterUK')"
            :class="v$.formData.nameAndAddressOfFirstWorkAfterUK.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            placeholder="EG: HSBC, The Docks, Singapore"
          ></b-form-input>
          <div
            v-for="error of v$.formData.nameAndAddressOfFirstWorkAfterUK.$errors"
            class="invalid-feedback"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6"><label>8) Approx When Did You Stop Working There?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model="formData.approxDateStopFirstWorking"
            type="date"
            @input="setTouched('approxDateStopFirstWorking')"
            :class="v$.formData.approxDateStopFirstWorking.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            :max="todayDate"
            min="1900-01-01"
          ></b-form-input>
          <div
            v-for="error of v$.formData.approxDateStopFirstWorking.$errors"
            class="invalid-feedback"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6"><label>9) What Have You Done, Where 2006-NOW? With Approx Dates</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model.trim="formData.youDone"
            @input="setTouched('youDone')"
            :class="v$.formData.youDone.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            placeholder="EG: Intel, Kildare, Jan 2006-Mar 2012 etc"
          ></b-form-input>
          <div v-for="error of v$.formData.youDone.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
          <b-form-text>
            <b>Tip:</b>See Info We Need:
            <a href="https://xpen.uk/work" target="_blank">https://xpen.uk/work</a>
          </b-form-text>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6"><label>10) What's Your National Insurance No?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <input
            type="text"
            v-mask="'UU 00 00 00 U'"
            placeholder="XX XX XX XX X"
            class="form-control"
            maxlength="13"
            @input="setTouched('nationalInsuranceNumber')"
            :class="v$.formData.nationalInsuranceNumber.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            v-model="formData.nationalInsuranceNumber"
          />
          <div v-for="error of v$.formData.nationalInsuranceNumber.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
          <b-form-text>
            <b>Tip:</b> How to Get it:
            <a href="https://xpen.uk/5ways" target="_blank">https://xpen.uk/5ways</a>
          </b-form-text>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6">
        <label>11) Did You Request PRSI/Social Ins Statement? We Need it</label>
      </b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-select
            v-model="formData.requestPRSIorSocialInsStatement"
            :options="yesNoOptions"
            @input="setTouched('requestPRSIorSocialInsStatement')"
            :class="v$.formData.requestPRSIorSocialInsStatement.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
          ></b-form-select>
          <div
            v-for="error of v$.formData.requestPRSIorSocialInsStatement.$errors"
            class="invalid-feedback"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
          <b-form-text>
            <b>Tip:</b> How to Get it:
            <a href="https://xpen.uk/socins" target="_blank">https://xpen.uk/socins</a>
          </b-form-text>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6">
        <label>12) How Many Years Were You in The UK?</label>
      </b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-select
            v-model="formData.yearsInTheUK"
            :options="yearsOptions"
            @input="setTouched('yearsInTheUK')"
            :class="v$.formData.yearsInTheUK.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
          ></b-form-select>
          <div v-for="error of v$.formData.yearsInTheUK.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import 'vue-search-select/dist/VueSearchSelect.css';
import { minLength, maxLength, helpers } from '@vuelidate/validators';
const insuranceNumberRegex = helpers.regex(/^[A-Z]{2} \d{2} \d{2} \d{2} [A-D]$/);
export default {
  name: 'MobileForm',
  inject: ['occupationOptions', 'countryListOptions'],
  props: {
    formData: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    onSubmit: { type: Function, required: true }
  },
  data() {
    return {
      v$: useVuelidate(),
      todayDate: new Date().toISOString().slice(0, 10),
      maritalStatusOptions: [
        { value: null, text: 'Choose' },
        { value: 'Single', text: 'Single' },
        { value: 'Married', text: 'Married' },
        { value: 'Divorced', text: 'Divorced' },
        { value: 'Bereaved', text: 'Bereaved' }
      ],
      yesNoOptions: [
        { value: false, text: 'No' },
        { value: true, text: 'Yes' }
      ],
      yearsOptions: [
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
        { value: 4, text: 4 },
        { value: 5, text: 5 },
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 },
        { value: 11, text: 11 },
        { value: 12, text: 12 },
        { value: 13, text: 13 },
        { value: 14, text: 14 },
        { value: 15, text: 15 },
        { value: 16, text: 16 },
        { value: 17, text: 17 },
        { value: 18, text: 18 },
        { value: 19, text: 19 },
        { value: 20, text: 20 },
        { value: 21, text: 21 },
        { value: 22, text: 22 },
        { value: 23, text: 23 },
        { value: 24, text: 24 },
        { value: 25, text: 25 },
        { value: 26, text: 26 },
        { value: 27, text: 27 },
        { value: 28, text: 28 },
        { value: 29, text: 29 },
        { value: 30, text: 30 },
        { value: 31, text: 31 },
        { value: 32, text: 32 },
        { value: 33, text: 33 },
        { value: 34, text: 34 },
        { value: 35, text: 35 }
      ]
    };
  },
  validations() {
    return {
      formData: {
        yourName: {
          // required: helpers.withMessage('Answer required', required),
          minLength: minLength(2),
          maxLength: maxLength(40)
        },
        yourDateOfBirth: {
          // required: helpers.withMessage('Answer required', required),
          // minValue: helpers.withMessage('Invalid Date.', (value) => {
          //   return new Date(value) > new Date('1900-01-01');
          // }),
          // maxValue: helpers.withMessage('Date not must be after today', (value) => {
          //   return new Date(value) < new Date();
          // })
        },
        yourMaritalStatus: {
          // required: helpers.withMessage('Answer required', required)
        },
        nameAndAddressOfLastUKEmployed: {
          // required: helpers.withMessage('Answer required', required)
        },
        approxDateLeaveUK: {
          // required: helpers.withMessage('Answer required', required),
          // minValue: helpers.withMessage('Invalid Date.', (value) => {
          //   return new Date(value) > new Date('1900-01-01');
          // }),
          // maxValue: helpers.withMessage('Date not must be after today', (value) => {
          //   return new Date(value) < new Date();
          // })
        },
        youWorkingImmediatelyBeforeLeavingUK: {
          // required: helpers.withMessage('Answer required', required)
        },
        nameAndAddressOfFirstWorkAfterUK: {
          // required: helpers.withMessage('Answer required', required)
        },
        approxDateStopFirstWorking: {
          // required: helpers.withMessage('Answer required', required),
          // minValue: helpers.withMessage('Invalid Date.', (value) => {
          //   return new Date(value) > new Date('1900-01-01');
          // }),
          // maxValue: helpers.withMessage('Date not must be after today', (value) => {
          //   return new Date(value) < new Date();
          // })
        },
        youDone: {
          // required: helpers.withMessage('Answer required', required)
        },
        nationalInsuranceNumber: {
          insuranceNumberRegex,
          maxLength: maxLength(13)
        },
        requestPRSIorSocialInsStatement: {
          // required: helpers.withMessage('Answer required', required)
        },
        yearsInTheUK: {
          // required: helpers.withMessage('Answer required', required)
        }
      }
    };
  },
  methods: {
    setTouched(theModel) {
      if (theModel === 'yourName' || theModel == 'all') {
        this.v$.formData.yourName.$touch();
      }
      if (theModel === 'yourDateOfBirth' || theModel == 'all') {
        this.v$.formData.yourDateOfBirth.$touch();
      }
      if (theModel === 'yourMaritalStatus' || theModel == 'all') {
        this.v$.formData.yourMaritalStatus.$touch();
      }
      if (theModel === 'nameAndAddressOfLastUKEmployed' || theModel == 'all') {
        this.v$.formData.nameAndAddressOfLastUKEmployed.$touch();
      }
      if (theModel === 'approxDateLeaveUK' || theModel == 'all') {
        this.v$.formData.approxDateLeaveUK.$touch();
      }
      if (theModel === 'youWorkingImmediatelyBeforeLeavingUK' || theModel == 'all') {
        this.v$.formData.youWorkingImmediatelyBeforeLeavingUK.$touch();
      }
      if (theModel === 'nameAndAddressOfFirstWorkAfterUK' || theModel == 'all') {
        this.v$.formData.nameAndAddressOfFirstWorkAfterUK.$touch();
      }
      if (theModel === 'approxDateStopFirstWorking' || theModel == 'all') {
        this.v$.formData.approxDateStopFirstWorking.$touch();
      }
      if (theModel === 'youDone' || theModel == 'all') {
        this.v$.formData.youDone.$touch();
      }
      if (theModel === 'nationalInsuranceNumber' || theModel == 'all') {
        this.v$.formData.nationalInsuranceNumber.$touch();
      }
      if (theModel === 'requestPRSIorSocialInsStatement' || theModel == 'all') {
        this.v$.formData.requestPRSIorSocialInsStatement.$touch();
      }
      if (theModel === 'yearsInTheUK' || theModel == 'all') {
        this.v$.formData.yearsInTheUK.$touch();
      }
    },
    submitForm() {
      this.setTouched('all');
      if (!this.v$.$invalid) {
        this.onSubmit();
      }
    }
  }
};
</script>
<style>
.ui.search.dropdown > .text {
  color: var(--bs-body-color);
  font-size: 1rem;
  font-weight: 400;
}
.ui.search.selection.dropdown > input.search {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  margin-bottom: 1.5rem;
}
</style>
