export default [
  {
    value: null,
    text: 'Choose'
  },
  {
    value: 'accountant',
    text: 'Accountant'
  },
  {
    value: 'actor',
    text: 'Actor'
  },
  {
    value: 'actuary',
    text: 'Actuary'
  },
  {
    value: 'adhesive bonding machine tender',
    text: 'Adhesive bonding machine tender'
  },
  {
    value: 'adjudicator',
    text: 'Adjudicator'
  },
  {
    value: 'administrative assistant',
    text: 'Administrative assistant'
  },
  {
    value: 'administrative services manager',
    text: 'Administrative services manager'
  },
  {
    value: 'adult education teacher',
    text: 'Adult education teacher'
  },
  {
    value: 'advertising manager',
    text: 'Advertising manager'
  },
  {
    value: 'advertising sales agent',
    text: 'Advertising sales agent'
  },
  {
    value: 'aerobics instructor',
    text: 'Aerobics instructor'
  },
  {
    value: 'aerospace engineer',
    text: 'Aerospace engineer'
  },
  {
    value: 'aerospace engineering technician',
    text: 'Aerospace engineering technician'
  },
  {
    value: 'agent',
    text: 'Agent'
  },
  {
    value: 'agricultural engineer',
    text: 'Agricultural engineer'
  },
  {
    value: 'agricultural equipment operator',
    text: 'Agricultural equipment operator'
  },
  {
    value: 'agricultural grader',
    text: 'Agricultural grader'
  },
  {
    value: 'agricultural inspector',
    text: 'Agricultural inspector'
  },
  {
    value: 'agricultural manager',
    text: 'Agricultural manager'
  },
  {
    value: 'agricultural sciences teacher',
    text: 'Agricultural sciences teacher'
  },
  {
    value: 'agricultural sorter',
    text: 'Agricultural sorter'
  },
  {
    value: 'agricultural technician',
    text: 'Agricultural technician'
  },
  {
    value: 'agricultural worker',
    text: 'Agricultural worker'
  },
  {
    value: 'air conditioning installer',
    text: 'Air conditioning installer'
  },
  {
    value: 'air conditioning mechanic',
    text: 'Air conditioning mechanic'
  },
  {
    value: 'air traffic controller',
    text: 'Air traffic controller'
  },
  {
    value: 'aircraft cargo handling supervisor',
    text: 'Aircraft cargo handling supervisor'
  },
  {
    value: 'aircraft mechanic',
    text: 'Aircraft mechanic'
  },
  {
    value: 'aircraft service technician',
    text: 'Aircraft service technician'
  },
  {
    value: 'airline copilot',
    text: 'Airline copilot'
  },
  {
    value: 'airline pilot',
    text: 'Airline pilot'
  },
  {
    value: 'ambulance dispatcher',
    text: 'Ambulance dispatcher'
  },
  {
    value: 'ambulance driver',
    text: 'Ambulance driver'
  },
  {
    value: 'amusement machine servicer',
    text: 'Amusement machine servicer'
  },
  {
    value: 'anesthesiologist',
    text: 'Anesthesiologist'
  },
  {
    value: 'animal breeder',
    text: 'Animal breeder'
  },
  {
    value: 'animal control worker',
    text: 'Animal control worker'
  },
  {
    value: 'animal scientist',
    text: 'Animal scientist'
  },
  {
    value: 'animal trainer',
    text: 'Animal trainer'
  },
  {
    value: 'animator',
    text: 'Animator'
  },
  {
    value: 'answering service operator',
    text: 'Answering service operator'
  },
  {
    value: 'anthropologist',
    text: 'Anthropologist'
  },
  {
    value: 'apparel patternmaker',
    text: 'Apparel patternmaker'
  },
  {
    value: 'apparel worker',
    text: 'Apparel worker'
  },
  {
    value: 'arbitrator',
    text: 'Arbitrator'
  },
  {
    value: 'archeologist',
    text: 'Archeologist'
  },
  {
    value: 'architect',
    text: 'Architect'
  },
  {
    value: 'architectural drafter',
    text: 'Architectural drafter'
  },
  {
    value: 'architectural manager',
    text: 'Architectural manager'
  },
  {
    value: 'archivist',
    text: 'Archivist'
  },
  {
    value: 'art director',
    text: 'Art director'
  },
  {
    value: 'art teacher',
    text: 'Art teacher'
  },
  {
    value: 'artist',
    text: 'Artist'
  },
  {
    value: 'assembler',
    text: 'Assembler'
  },
  {
    value: 'astronomer',
    text: 'Astronomer'
  },
  {
    value: 'athlete',
    text: 'Athlete'
  },
  {
    value: 'athletic trainer',
    text: 'Athletic trainer'
  },
  {
    value: 'atm machine repairer',
    text: 'ATM machine repairer'
  },
  {
    value: 'atmospheric scientist',
    text: 'Atmospheric scientist'
  },
  {
    value: 'attendant',
    text: 'Attendant'
  },
  {
    value: 'audio and video equipment technician',
    text: 'Audio and video equipment technician'
  },
  {
    value: 'audio-visual and multimedia collections specialist',
    text: 'Audio-visual and multimedia collections specialist'
  },
  {
    value: 'audiologist',
    text: 'Audiologist'
  },
  {
    value: 'auditor',
    text: 'Auditor'
  },
  {
    value: 'author',
    text: 'Author'
  },
  {
    value: 'auto damage insurance appraiser',
    text: 'Auto damage insurance appraiser'
  },
  {
    value: 'automotive and watercraft service attendant',
    text: 'Automotive and watercraft service attendant'
  },
  {
    value: 'automotive glass installer',
    text: 'Automotive glass installer'
  },
  {
    value: 'automotive mechanic',
    text: 'Automotive mechanic'
  },
  {
    value: 'avionics technician',
    text: 'Avionics technician'
  },
  {
    value: 'back-end developer',
    text: 'Back-end developer'
  },
  {
    value: 'baggage porter',
    text: 'Baggage porter'
  },
  {
    value: 'bailiff',
    text: 'Bailiff'
  },
  {
    value: 'baker',
    text: 'Baker'
  },
  {
    value: 'barback',
    text: 'Barback'
  },
  {
    value: 'barber',
    text: 'Barber'
  },
  {
    value: 'bartender',
    text: 'Bartender'
  },
  {
    value: 'basic education teacher',
    text: 'Basic education teacher'
  },
  {
    value: 'behavioral disorder counselor',
    text: 'Behavioral disorder counselor'
  },
  {
    value: 'bellhop',
    text: 'Bellhop'
  },
  {
    value: 'bench carpenter',
    text: 'Bench carpenter'
  },
  {
    value: 'bicycle repairer',
    text: 'Bicycle repairer'
  },
  {
    value: 'bill and account collector',
    text: 'Bill and account collector'
  },
  {
    value: 'billing and posting clerk',
    text: 'Billing and posting clerk'
  },
  {
    value: 'biochemist',
    text: 'Biochemist'
  },
  {
    value: 'biological technician',
    text: 'Biological technician'
  },
  {
    value: 'biomedical engineer',
    text: 'Biomedical engineer'
  },
  {
    value: 'biophysicist',
    text: 'Biophysicist'
  },
  {
    value: 'blaster',
    text: 'Blaster'
  },
  {
    value: 'blending machine operator',
    text: 'Blending machine operator'
  },
  {
    value: 'blockmason',
    text: 'Blockmason'
  },
  {
    value: 'boiler operator',
    text: 'Boiler operator'
  },
  {
    value: 'boilermaker',
    text: 'Boilermaker'
  },
  {
    value: 'bookkeeper',
    text: 'Bookkeeper'
  },
  {
    value: 'boring machine tool tender',
    text: 'Boring machine tool tender'
  },
  {
    value: 'brazer',
    text: 'Brazer'
  },
  {
    value: 'brickmason',
    text: 'Brickmason'
  },
  {
    value: 'bridge and lock tender',
    text: 'Bridge and lock tender'
  },
  {
    value: 'broadcast news analyst',
    text: 'Broadcast news analyst'
  },
  {
    value: 'broadcast technician',
    text: 'Broadcast technician'
  },
  {
    value: 'brokerage clerk',
    text: 'Brokerage clerk'
  },
  {
    value: 'budget analyst',
    text: 'Budget analyst'
  },
  {
    value: 'building inspector',
    text: 'Building inspector'
  },
  {
    value: 'bus mechanic',
    text: 'Bus mechanic'
  },
  {
    value: 'butcher',
    text: 'Butcher'
  },
  {
    value: 'buyer',
    text: 'Buyer'
  },
  {
    value: 'cabinetmaker',
    text: 'Cabinetmaker'
  },
  {
    value: 'cafeteria attendant',
    text: 'Cafeteria attendant'
  },
  {
    value: 'cafeteria cook',
    text: 'Cafeteria cook'
  },
  {
    value: 'camera operator',
    text: 'Camera operator'
  },
  {
    value: 'camera repairer',
    text: 'Camera repairer'
  },
  {
    value: 'cardiovascular technician',
    text: 'Cardiovascular technician'
  },
  {
    value: 'cargo agent',
    text: 'Cargo agent'
  },
  {
    value: 'carpenter',
    text: 'Carpenter'
  },
  {
    value: 'carpet installer',
    text: 'Carpet installer'
  },
  {
    value: 'cartographer',
    text: 'Cartographer'
  },
  {
    value: 'cashier',
    text: 'Cashier'
  },
  {
    value: 'caster',
    text: 'Caster'
  },
  {
    value: 'ceiling tile installer',
    text: 'Ceiling tile installer'
  },
  {
    value: 'cellular equipment installer',
    text: 'Cellular equipment installer'
  },
  {
    value: 'cement mason',
    text: 'Cement mason'
  },
  {
    value: 'channeling machine operator',
    text: 'Channeling machine operator'
  },
  {
    value: 'chauffeur',
    text: 'Chauffeur'
  },
  {
    value: 'checker',
    text: 'Checker'
  },
  {
    value: 'chef',
    text: 'Chef'
  },
  {
    value: 'chemical engineer',
    text: 'Chemical engineer'
  },
  {
    value: 'chemical plant operator',
    text: 'Chemical plant operator'
  },
  {
    value: 'chemist',
    text: 'Chemist'
  },
  {
    value: 'chemistry teacher',
    text: 'Chemistry teacher'
  },
  {
    value: 'chief executive',
    text: 'Chief executive'
  },
  {
    value: 'child social worker',
    text: 'Child social worker'
  },
  {
    value: 'childcare worker',
    text: 'Childcare worker'
  },
  {
    value: 'chiropractor',
    text: 'Chiropractor'
  },
  {
    value: 'choreographer',
    text: 'Choreographer'
  },
  {
    value: 'civil drafter',
    text: 'Civil drafter'
  },
  {
    value: 'civil engineer',
    text: 'Civil engineer'
  },
  {
    value: 'civil engineering technician',
    text: 'Civil engineering technician'
  },
  {
    value: 'claims adjuster',
    text: 'Claims adjuster'
  },
  {
    value: 'claims examiner',
    text: 'Claims examiner'
  },
  {
    value: 'claims investigator',
    text: 'Claims investigator'
  },
  {
    value: 'cleaner',
    text: 'Cleaner'
  },
  {
    value: 'clinical laboratory technician',
    text: 'Clinical laboratory technician'
  },
  {
    value: 'clinical laboratory technologist',
    text: 'Clinical laboratory technologist'
  },
  {
    value: 'clinical psychologist',
    text: 'Clinical psychologist'
  },
  {
    value: 'coating worker',
    text: 'Coating worker'
  },
  {
    value: 'coatroom attendant',
    text: 'Coatroom attendant'
  },
  {
    value: 'coil finisher',
    text: 'Coil finisher'
  },
  {
    value: 'coil taper',
    text: 'Coil taper'
  },
  {
    value: 'coil winder',
    text: 'Coil winder'
  },
  {
    value: 'coin machine servicer',
    text: 'Coin machine servicer'
  },
  {
    value: 'commercial diver',
    text: 'Commercial diver'
  },
  {
    value: 'commercial pilot',
    text: 'Commercial pilot'
  },
  {
    value: 'commodities sales agent',
    text: 'Commodities sales agent'
  },
  {
    value: 'communications equipment operator',
    text: 'Communications equipment operator'
  },
  {
    value: 'communications teacher',
    text: 'Communications teacher'
  },
  {
    value: 'community association manager',
    text: 'Community association manager'
  },
  {
    value: 'community service manager',
    text: 'Community service manager'
  },
  {
    value: 'compensation and benefits manager',
    text: 'Compensation and benefits manager'
  },
  {
    value: 'compliance officer',
    text: 'Compliance officer'
  },
  {
    value: 'composer',
    text: 'Composer'
  },
  {
    value: 'computer hardware engineer',
    text: 'Computer hardware engineer'
  },
  {
    value: 'computer network architect',
    text: 'Computer network architect'
  },
  {
    value: 'computer operator',
    text: 'Computer operator'
  },
  {
    value: 'computer programmer',
    text: 'Computer programmer'
  },
  {
    value: 'computer science teacher',
    text: 'Computer science teacher'
  },
  {
    value: 'computer support specialist',
    text: 'Computer support specialist'
  },
  {
    value: 'computer systems administrator',
    text: 'Computer systems administrator'
  },
  {
    value: 'computer systems analyst',
    text: 'Computer systems analyst'
  },
  {
    value: 'concierge',
    text: 'Concierge'
  },
  {
    value: 'conciliator',
    text: 'Conciliator'
  },
  {
    value: 'concrete finisher',
    text: 'Concrete finisher'
  },
  {
    value: 'conservation science teacher',
    text: 'Conservation science teacher'
  },
  {
    value: 'conservation scientist',
    text: 'Conservation scientist'
  },
  {
    value: 'conservation worker',
    text: 'Conservation worker'
  },
  {
    value: 'conservator',
    text: 'Conservator'
  },
  {
    value: 'construction inspector',
    text: 'Construction inspector'
  },
  {
    value: 'construction manager',
    text: 'Construction manager'
  },
  {
    value: 'construction painter',
    text: 'Construction painter'
  },
  {
    value: 'construction worker',
    text: 'Construction worker'
  },
  {
    value: 'continuous mining machine operator',
    text: 'Continuous mining machine operator'
  },
  {
    value: 'convention planner',
    text: 'Convention planner'
  },
  {
    value: 'conveyor operator',
    text: 'Conveyor operator'
  },
  {
    value: 'cook',
    text: 'Cook'
  },
  {
    value: 'cooling equipment operator',
    text: 'Cooling equipment operator'
  },
  {
    value: 'copy marker',
    text: 'Copy marker'
  },
  {
    value: 'correctional officer',
    text: 'Correctional officer'
  },
  {
    value: 'correctional treatment specialist',
    text: 'Correctional treatment specialist'
  },
  {
    value: 'correspondence clerk',
    text: 'Correspondence clerk'
  },
  {
    value: 'correspondent',
    text: 'Correspondent'
  },
  {
    value: 'cosmetologist',
    text: 'Cosmetologist'
  },
  {
    value: 'cost estimator',
    text: 'Cost estimator'
  },
  {
    value: 'costume attendant',
    text: 'Costume attendant'
  },
  {
    value: 'counseling psychologist',
    text: 'Counseling psychologist'
  },
  {
    value: 'counselor',
    text: 'Counselor'
  },
  {
    value: 'courier',
    text: 'Courier'
  },
  {
    value: 'court reporter',
    text: 'Court reporter'
  },
  {
    value: 'craft artist',
    text: 'Craft artist'
  },
  {
    value: 'crane operator',
    text: 'Crane operator'
  },
  {
    value: 'credit analyst',
    text: 'Credit analyst'
  },
  {
    value: 'credit checker',
    text: 'Credit checker'
  },
  {
    value: 'credit counselor',
    text: 'Credit counselor'
  },
  {
    value: 'criminal investigator',
    text: 'Criminal investigator'
  },
  {
    value: 'criminal justice teacher',
    text: 'Criminal justice teacher'
  },
  {
    value: 'crossing guard',
    text: 'Crossing guard'
  },
  {
    value: 'curator',
    text: 'Curator'
  },
  {
    value: 'custom sewer',
    text: 'Custom sewer'
  },
  {
    value: 'customer service representative',
    text: 'Customer service representative'
  },
  {
    value: 'cutter',
    text: 'Cutter'
  },
  {
    value: 'cutting machine operator',
    text: 'Cutting machine operator'
  },
  {
    value: 'dancer',
    text: 'Dancer'
  },
  {
    value: 'data entry keyer',
    text: 'Data entry keyer'
  },
  {
    value: 'database administrator',
    text: 'Database administrator'
  },
  {
    value: 'decorating worker',
    text: 'Decorating worker'
  },
  {
    value: 'delivery services driver',
    text: 'Delivery services driver'
  },
  {
    value: 'demonstrator',
    text: 'Demonstrator'
  },
  {
    value: 'dental assistant',
    text: 'Dental assistant'
  },
  {
    value: 'dental hygienist',
    text: 'Dental hygienist'
  },
  {
    value: 'dental laboratory technician',
    text: 'Dental laboratory technician'
  },
  {
    value: 'dentist',
    text: 'Dentist'
  },
  {
    value: 'dermatologist',
    text: 'Dermatologist'
  },
  {
    value: 'derrick operator',
    text: 'Derrick operator'
  },
  {
    value: 'designer',
    text: 'Designer'
  },
  {
    value: 'desktop publisher',
    text: 'Desktop publisher'
  },
  {
    value: 'detective',
    text: 'Detective'
  },
  {
    value: 'developer',
    text: 'Developer'
  },
  {
    value: 'diagnostic medical sonographer',
    text: 'Diagnostic medical sonographer'
  },
  {
    value: 'die maker',
    text: 'Die maker'
  },
  {
    value: 'diesel engine specialist',
    text: 'Diesel engine specialist'
  },
  {
    value: 'dietetic technician',
    text: 'Dietetic technician'
  },
  {
    value: 'dietitian',
    text: 'Dietitian'
  },
  {
    value: 'dinkey operator',
    text: 'Dinkey operator'
  },
  {
    value: 'director',
    text: 'Director'
  },
  {
    value: 'dishwasher',
    text: 'Dishwasher'
  },
  {
    value: 'dispatcher',
    text: 'Dispatcher'
  },
  {
    value: 'dj',
    text: 'DJ'
  },
  {
    value: 'doctor',
    text: 'Doctor'
  },
  {
    value: 'door-to-door sales worker',
    text: 'Door-to-door sales worker'
  },
  {
    value: 'drafter',
    text: 'Drafter'
  },
  {
    value: 'dragline operator',
    text: 'Dragline operator'
  },
  {
    value: 'drama teacher',
    text: 'Drama teacher'
  },
  {
    value: 'dredge operator',
    text: 'Dredge operator'
  },
  {
    value: 'dressing room attendant',
    text: 'Dressing room attendant'
  },
  {
    value: 'dressmaker',
    text: 'Dressmaker'
  },
  {
    value: 'drier operator',
    text: 'Drier operator'
  },
  {
    value: 'drilling machine tool operator',
    text: 'Drilling machine tool operator'
  },
  {
    value: 'dry-cleaning worker',
    text: 'Dry-cleaning worker'
  },
  {
    value: 'drywall installer',
    text: 'Drywall installer'
  },
  {
    value: 'dyeing machine operator',
    text: 'Dyeing machine operator'
  },
  {
    value: 'earth driller',
    text: 'Earth driller'
  },
  {
    value: 'economics teacher',
    text: 'Economics teacher'
  },
  {
    value: 'economist',
    text: 'Economist'
  },
  {
    value: 'editor',
    text: 'Editor'
  },
  {
    value: 'education administrator',
    text: 'Education administrator'
  },
  {
    value: 'electric motor repairer',
    text: 'Electric motor repairer'
  },
  {
    value: 'electrical electronics drafter',
    text: 'Electrical electronics drafter'
  },
  {
    value: 'electrical engineer',
    text: 'Electrical engineer'
  },
  {
    value: 'electrical equipment assembler',
    text: 'Electrical equipment assembler'
  },
  {
    value: 'electrical installer',
    text: 'Electrical installer'
  },
  {
    value: 'electrical power-line installer',
    text: 'Electrical power-line installer'
  },
  {
    value: 'electrician',
    text: 'Electrician'
  },
  {
    value: 'electro-mechanical technician',
    text: 'Electro-mechanical technician'
  },
  {
    value: 'elementary school teacher',
    text: 'Elementary school teacher'
  },
  {
    value: 'elevator installer',
    text: 'Elevator installer'
  },
  {
    value: 'elevator repairer',
    text: 'Elevator repairer'
  },
  {
    value: 'embalmer',
    text: 'Embalmer'
  },
  {
    value: 'emergency management director',
    text: 'Emergency management director'
  },
  {
    value: 'emergency medical technician',
    text: 'Emergency medical technician'
  },
  {
    value: 'engine assembler',
    text: 'Engine assembler'
  },
  {
    value: 'engineer',
    text: 'Engineer'
  },
  {
    value: 'engineering manager',
    text: 'Engineering manager'
  },
  {
    value: 'engineering teacher',
    text: 'Engineering teacher'
  },
  {
    value: 'english language teacher',
    text: 'English language teacher'
  },
  {
    value: 'engraver',
    text: 'Engraver'
  },
  {
    value: 'entertainment attendant',
    text: 'Entertainment attendant'
  },
  {
    value: 'environmental engineer',
    text: 'Environmental engineer'
  },
  {
    value: 'environmental science teacher',
    text: 'Environmental science teacher'
  },
  {
    value: 'environmental scientist',
    text: 'Environmental scientist'
  },
  {
    value: 'epidemiologist',
    text: 'Epidemiologist'
  },
  {
    value: 'escort',
    text: 'Escort'
  },
  {
    value: 'etcher',
    text: 'Etcher'
  },
  {
    value: 'event planner',
    text: 'Event planner'
  },
  {
    value: 'excavating operator',
    text: 'Excavating operator'
  },
  {
    value: 'executive administrative assistant',
    text: 'Executive administrative assistant'
  },
  {
    value: 'executive secretary',
    text: 'Executive secretary'
  },
  {
    value: 'exhibit designer',
    text: 'Exhibit designer'
  },
  {
    value: 'expediting clerk',
    text: 'Expediting clerk'
  },
  {
    value: 'explosives worker',
    text: 'Explosives worker'
  },
  {
    value: 'extraction worker',
    text: 'Extraction worker'
  },
  {
    value: 'fabric mender',
    text: 'Fabric mender'
  },
  {
    value: 'fabric patternmaker',
    text: 'Fabric patternmaker'
  },
  {
    value: 'fabricator',
    text: 'Fabricator'
  },
  {
    value: 'faller',
    text: 'Faller'
  },
  {
    value: 'family practitioner',
    text: 'Family practitioner'
  },
  {
    value: 'family social worker',
    text: 'Family social worker'
  },
  {
    value: 'family therapist',
    text: 'Family therapist'
  },
  {
    value: 'farm advisor',
    text: 'Farm advisor'
  },
  {
    value: 'farm equipment mechanic',
    text: 'Farm equipment mechanic'
  },
  {
    value: 'farm labor contractor',
    text: 'Farm labor contractor'
  },
  {
    value: 'farmer',
    text: 'Farmer'
  },
  {
    value: 'farmworker',
    text: 'Farmworker'
  },
  {
    value: 'fashion designer',
    text: 'Fashion designer'
  },
  {
    value: 'fast food cook',
    text: 'Fast food cook'
  },
  {
    value: 'fence erector',
    text: 'Fence erector'
  },
  {
    value: 'fiberglass fabricator',
    text: 'Fiberglass fabricator'
  },
  {
    value: 'fiberglass laminator',
    text: 'Fiberglass laminator'
  },
  {
    value: 'file clerk',
    text: 'File clerk'
  },
  {
    value: 'filling machine operator',
    text: 'Filling machine operator'
  },
  {
    value: 'film and video editor',
    text: 'Film and video editor'
  },
  {
    value: 'financial analyst',
    text: 'Financial analyst'
  },
  {
    value: 'financial examiner',
    text: 'Financial examiner'
  },
  {
    value: 'financial manager',
    text: 'Financial manager'
  },
  {
    value: 'financial services sales agent',
    text: 'Financial services sales agent'
  },
  {
    value: 'fine artist',
    text: 'Fine artist'
  },
  {
    value: 'fire alarm system installer',
    text: 'Fire alarm system installer'
  },
  {
    value: 'fire dispatcher',
    text: 'Fire dispatcher'
  },
  {
    value: 'fire inspector',
    text: 'Fire inspector'
  },
  {
    value: 'fire investigator',
    text: 'Fire investigator'
  },
  {
    value: 'firefighter',
    text: 'Firefighter'
  },
  {
    value: 'fish and game warden',
    text: 'Fish and game warden'
  },
  {
    value: 'fish cutter',
    text: 'Fish cutter'
  },
  {
    value: 'fish trimmer',
    text: 'Fish trimmer'
  },
  {
    value: 'fisher',
    text: 'Fisher'
  },
  {
    value: 'fitness studies teacher',
    text: 'Fitness studies teacher'
  },
  {
    value: 'fitness trainer',
    text: 'Fitness trainer'
  },
  {
    value: 'flight attendant',
    text: 'Flight attendant'
  },
  {
    value: 'floor finisher',
    text: 'Floor finisher'
  },
  {
    value: 'floor layer',
    text: 'Floor layer'
  },
  {
    value: 'floor sander',
    text: 'Floor sander'
  },
  {
    value: 'floral designer',
    text: 'Floral designer'
  },
  {
    value: 'food batchmaker',
    text: 'Food batchmaker'
  },
  {
    value: 'food cooking machine operator',
    text: 'Food cooking machine operator'
  },
  {
    value: 'food preparation worker',
    text: 'Food preparation worker'
  },
  {
    value: 'food science technician',
    text: 'Food science technician'
  },
  {
    value: 'food scientist',
    text: 'Food scientist'
  },
  {
    value: 'food server',
    text: 'Food server'
  },
  {
    value: 'food service manager',
    text: 'Food service manager'
  },
  {
    value: 'food technologist',
    text: 'Food technologist'
  },
  {
    value: 'foreign language teacher',
    text: 'Foreign language teacher'
  },
  {
    value: 'foreign literature teacher',
    text: 'Foreign literature teacher'
  },
  {
    value: 'forensic science technician',
    text: 'Forensic science technician'
  },
  {
    value: 'forest fire inspector',
    text: 'Forest fire inspector'
  },
  {
    value: 'forest fire prevention specialist',
    text: 'Forest fire prevention specialist'
  },
  {
    value: 'forest worker',
    text: 'Forest worker'
  },
  {
    value: 'forester',
    text: 'Forester'
  },
  {
    value: 'forestry teacher',
    text: 'Forestry teacher'
  },
  {
    value: 'forging machine setter',
    text: 'Forging machine setter'
  },
  {
    value: 'foundry coremaker',
    text: 'Foundry coremaker'
  },
  {
    value: 'freight agent',
    text: 'Freight agent'
  },
  {
    value: 'freight mover',
    text: 'Freight mover'
  },
  {
    value: 'front-end developer',
    text: 'Front-end developer'
  },
  {
    value: 'fundraising manager',
    text: 'Fundraising manager'
  },
  {
    value: 'funeral attendant',
    text: 'Funeral attendant'
  },
  {
    value: 'funeral director',
    text: 'Funeral director'
  },
  {
    value: 'funeral service manager',
    text: 'Funeral service manager'
  },
  {
    value: 'furnace operator',
    text: 'Furnace operator'
  },
  {
    value: 'furnishings worker',
    text: 'Furnishings worker'
  },
  {
    value: 'furniture finisher',
    text: 'Furniture finisher'
  },
  {
    value: 'gaming booth cashier',
    text: 'Gaming booth cashier'
  },
  {
    value: 'gaming cage worker',
    text: 'Gaming cage worker'
  },
  {
    value: 'gaming change person',
    text: 'Gaming change person'
  },
  {
    value: 'gaming dealer',
    text: 'Gaming dealer'
  },
  {
    value: 'gaming investigator',
    text: 'Gaming investigator'
  },
  {
    value: 'gaming manager',
    text: 'Gaming manager'
  },
  {
    value: 'gaming surveillance officer',
    text: 'Gaming surveillance officer'
  },
  {
    value: 'garment mender',
    text: 'Garment mender'
  },
  {
    value: 'garment presser',
    text: 'Garment presser'
  },
  {
    value: 'gas compressor',
    text: 'Gas compressor'
  },
  {
    value: 'gas plant operator',
    text: 'Gas plant operator'
  },
  {
    value: 'gas pumping station operator',
    text: 'Gas pumping station operator'
  },
  {
    value: 'general manager',
    text: 'General manager'
  },
  {
    value: 'general practitioner',
    text: 'General practitioner'
  },
  {
    value: 'geographer',
    text: 'Geographer'
  },
  {
    value: 'geography teacher',
    text: 'Geography teacher'
  },
  {
    value: 'geological engineer',
    text: 'Geological engineer'
  },
  {
    value: 'geological technician',
    text: 'Geological technician'
  },
  {
    value: 'geoscientist',
    text: 'Geoscientist'
  },
  {
    value: 'glazier',
    text: 'Glazier'
  },
  {
    value: 'government program eligibility interviewer',
    text: 'Government program eligibility interviewer'
  },
  {
    value: 'graduate teaching assistant',
    text: 'Graduate teaching assistant'
  },
  {
    value: 'graphic designer',
    text: 'Graphic designer'
  },
  {
    value: 'groundskeeper',
    text: 'Groundskeeper'
  },
  {
    value: 'groundskeeping worker',
    text: 'Groundskeeping worker'
  },
  {
    value: 'gynecologist',
    text: 'Gynecologist'
  },
  {
    value: 'hairdresser',
    text: 'Hairdresser'
  },
  {
    value: 'hairstylist',
    text: 'Hairstylist'
  },
  {
    value: 'hand grinding worker',
    text: 'Hand grinding worker'
  },
  {
    value: 'hand laborer',
    text: 'Hand laborer'
  },
  {
    value: 'hand packager',
    text: 'Hand packager'
  },
  {
    value: 'hand packer',
    text: 'Hand packer'
  },
  {
    value: 'hand polishing worker',
    text: 'Hand polishing worker'
  },
  {
    value: 'hand sewer',
    text: 'Hand sewer'
  },
  {
    value: 'hazardous materials removal worker',
    text: 'Hazardous materials removal worker'
  },
  {
    value: 'head cook',
    text: 'Head cook'
  },
  {
    value: 'health and safety engineer',
    text: 'Health and safety engineer'
  },
  {
    value: 'health educator',
    text: 'Health educator'
  },
  {
    value: 'health information technician',
    text: 'Health information technician'
  },
  {
    value: 'health services manager',
    text: 'Health services manager'
  },
  {
    value: 'health specialties teacher',
    text: 'Health specialties teacher'
  },
  {
    value: 'healthcare social worker',
    text: 'Healthcare social worker'
  },
  {
    value: 'hearing officer',
    text: 'Hearing officer'
  },
  {
    value: 'heat treating equipment setter',
    text: 'Heat treating equipment setter'
  },
  {
    value: 'heating installer',
    text: 'Heating installer'
  },
  {
    value: 'heating mechanic',
    text: 'Heating mechanic'
  },
  {
    value: 'heavy truck driver',
    text: 'Heavy truck driver'
  },
  {
    value: 'highway maintenance worker',
    text: 'Highway maintenance worker'
  },
  {
    value: 'historian',
    text: 'Historian'
  },
  {
    value: 'history teacher',
    text: 'History teacher'
  },
  {
    value: 'hoist and winch operator',
    text: 'Hoist and winch operator'
  },
  {
    value: 'home appliance repairer',
    text: 'Home appliance repairer'
  },
  {
    value: 'home economics teacher',
    text: 'Home economics teacher'
  },
  {
    value: 'home entertainment installer',
    text: 'Home entertainment installer'
  },
  {
    value: 'home health aide',
    text: 'Home health aide'
  },
  {
    value: 'home management advisor',
    text: 'Home management advisor'
  },
  {
    value: 'host',
    text: 'Host'
  },
  {
    value: 'hostess',
    text: 'Hostess'
  },
  {
    value: 'hostler',
    text: 'Hostler'
  },
  {
    value: 'hotel desk clerk',
    text: 'Hotel desk clerk'
  },
  {
    value: 'housekeeping cleaner',
    text: 'Housekeeping cleaner'
  },
  {
    value: 'human resources assistant',
    text: 'Human resources assistant'
  },
  {
    value: 'human resources manager',
    text: 'Human resources manager'
  },
  {
    value: 'human service assistant',
    text: 'Human service assistant'
  },
  {
    value: 'hunter',
    text: 'Hunter'
  },
  {
    value: 'hydrologist',
    text: 'Hydrologist'
  },
  {
    value: 'illustrator',
    text: 'Illustrator'
  },
  {
    value: 'industrial designer',
    text: 'Industrial designer'
  },
  {
    value: 'industrial engineer',
    text: 'Industrial engineer'
  },
  {
    value: 'industrial engineering technician',
    text: 'Industrial engineering technician'
  },
  {
    value: 'industrial machinery mechanic',
    text: 'Industrial machinery mechanic'
  },
  {
    value: 'industrial production manager',
    text: 'Industrial production manager'
  },
  {
    value: 'industrial truck operator',
    text: 'Industrial truck operator'
  },
  {
    value: 'industrial-organizational psychologist',
    text: 'Industrial-organizational psychologist'
  },
  {
    value: 'information clerk',
    text: 'Information clerk'
  },
  {
    value: 'information research scientist',
    text: 'Information research scientist'
  },
  {
    value: 'information security analyst',
    text: 'Information security analyst'
  },
  {
    value: 'information systems manager',
    text: 'Information systems manager'
  },
  {
    value: 'inspector',
    text: 'Inspector'
  },
  {
    value: 'instructional coordinator',
    text: 'Instructional coordinator'
  },
  {
    value: 'instructor',
    text: 'Instructor'
  },
  {
    value: 'insulation worker',
    text: 'Insulation worker'
  },
  {
    value: 'insurance claims clerk',
    text: 'Insurance claims clerk'
  },
  {
    value: 'insurance sales agent',
    text: 'Insurance sales agent'
  },
  {
    value: 'insurance underwriter',
    text: 'Insurance underwriter'
  },
  {
    value: 'intercity bus driver',
    text: 'Intercity bus driver'
  },
  {
    value: 'interior designer',
    text: 'Interior designer'
  },
  {
    value: 'internist',
    text: 'Internist'
  },
  {
    value: 'interpreter',
    text: 'Interpreter'
  },
  {
    value: 'interviewer',
    text: 'Interviewer'
  },
  {
    value: 'investigator',
    text: 'Investigator'
  },
  {
    value: 'jailer',
    text: 'Jailer'
  },
  {
    value: 'janitor',
    text: 'Janitor'
  },
  {
    value: 'jeweler',
    text: 'Jeweler'
  },
  {
    value: 'judge',
    text: 'Judge'
  },
  {
    value: 'judicial law clerk',
    text: 'Judicial law clerk'
  },
  {
    value: 'kettle operator',
    text: 'Kettle operator'
  },
  {
    value: 'kiln operator',
    text: 'Kiln operator'
  },
  {
    value: 'kindergarten teacher',
    text: 'Kindergarten teacher'
  },
  {
    value: 'laboratory animal caretaker',
    text: 'Laboratory animal caretaker'
  },
  {
    value: 'landscape architect',
    text: 'Landscape architect'
  },
  {
    value: 'landscaping worker',
    text: 'Landscaping worker'
  },
  {
    value: 'lathe setter',
    text: 'Lathe setter'
  },
  {
    value: 'laundry worker',
    text: 'Laundry worker'
  },
  {
    value: 'law enforcement teacher',
    text: 'Law enforcement teacher'
  },
  {
    value: 'law teacher',
    text: 'Law teacher'
  },
  {
    value: 'lawyer',
    text: 'Lawyer'
  },
  {
    value: 'layout worker',
    text: 'Layout worker'
  },
  {
    value: 'leather worker',
    text: 'Leather worker'
  },
  {
    value: 'legal assistant',
    text: 'Legal assistant'
  },
  {
    value: 'legal secretary',
    text: 'Legal secretary'
  },
  {
    value: 'legislator',
    text: 'Legislator'
  },
  {
    value: 'librarian',
    text: 'Librarian'
  },
  {
    value: 'library assistant',
    text: 'Library assistant'
  },
  {
    value: 'library science teacher',
    text: 'Library science teacher'
  },
  {
    value: 'library technician',
    text: 'Library technician'
  },
  {
    value: 'licensed practical nurse',
    text: 'Licensed practical nurse'
  },
  {
    value: 'licensed vocational nurse',
    text: 'Licensed vocational nurse'
  },
  {
    value: 'life scientist',
    text: 'Life scientist'
  },
  {
    value: 'lifeguard',
    text: 'Lifeguard'
  },
  {
    value: 'light truck driver',
    text: 'Light truck driver'
  },
  {
    value: 'line installer',
    text: 'Line installer'
  },
  {
    value: 'literacy teacher',
    text: 'Literacy teacher'
  },
  {
    value: 'literature teacher',
    text: 'Literature teacher'
  },
  {
    value: 'loading machine operator',
    text: 'Loading machine operator'
  },
  {
    value: 'loan clerk',
    text: 'Loan clerk'
  },
  {
    value: 'loan interviewer',
    text: 'Loan interviewer'
  },
  {
    value: 'loan officer',
    text: 'Loan officer'
  },
  {
    value: 'lobby attendant',
    text: 'Lobby attendant'
  },
  {
    value: 'locker room attendant',
    text: 'Locker room attendant'
  },
  {
    value: 'locksmith',
    text: 'Locksmith'
  },
  {
    value: 'locomotive engineer',
    text: 'Locomotive engineer'
  },
  {
    value: 'locomotive firer',
    text: 'Locomotive firer'
  },
  {
    value: 'lodging manager',
    text: 'Lodging manager'
  },
  {
    value: 'log grader',
    text: 'Log grader'
  },
  {
    value: 'logging equipment operator',
    text: 'Logging equipment operator'
  },
  {
    value: 'logistician',
    text: 'Logistician'
  },
  {
    value: 'machine feeder',
    text: 'Machine feeder'
  },
  {
    value: 'machinist',
    text: 'Machinist'
  },
  {
    value: 'magistrate judge',
    text: 'Magistrate judge'
  },
  {
    value: 'magistrate',
    text: 'Magistrate'
  },
  {
    value: 'maid',
    text: 'Maid'
  },
  {
    value: 'mail clerk',
    text: 'Mail clerk'
  },
  {
    value: 'mail machine operator',
    text: 'Mail machine operator'
  },
  {
    value: 'mail superintendent',
    text: 'Mail superintendent'
  },
  {
    value: 'maintenance painter',
    text: 'Maintenance painter'
  },
  {
    value: 'maintenance worker',
    text: 'Maintenance worker'
  },
  {
    value: 'makeup artist',
    text: 'Makeup artist'
  },
  {
    value: 'management analyst',
    text: 'Management analyst'
  },
  {
    value: 'manicurist',
    text: 'Manicurist'
  },
  {
    value: 'manufactured building installer',
    text: 'Manufactured building installer'
  },
  {
    value: 'mapping technician',
    text: 'Mapping technician'
  },
  {
    value: 'marble setter',
    text: 'Marble setter'
  },
  {
    value: 'marine engineer',
    text: 'Marine engineer'
  },
  {
    value: 'marine oiler',
    text: 'Marine oiler'
  },
  {
    value: 'market research analyst',
    text: 'Market research analyst'
  },
  {
    value: 'marketing manager',
    text: 'Marketing manager'
  },
  {
    value: 'marketing specialist',
    text: 'Marketing specialist'
  },
  {
    value: 'marriage therapist',
    text: 'Marriage therapist'
  },
  {
    value: 'massage therapist',
    text: 'Massage therapist'
  },
  {
    value: 'material mover',
    text: 'Material mover'
  },
  {
    value: 'materials engineer',
    text: 'Materials engineer'
  },
  {
    value: 'materials scientist',
    text: 'Materials scientist'
  },
  {
    value: 'mathematical science teacher',
    text: 'Mathematical science teacher'
  },
  {
    value: 'mathematical technician',
    text: 'Mathematical technician'
  },
  {
    value: 'mathematician',
    text: 'Mathematician'
  },
  {
    value: 'maxillofacial surgeon',
    text: 'Maxillofacial surgeon'
  },
  {
    value: 'measurer',
    text: 'Measurer'
  },
  {
    value: 'meat cutter',
    text: 'Meat cutter'
  },
  {
    value: 'meat packer',
    text: 'Meat packer'
  },
  {
    value: 'meat trimmer',
    text: 'Meat trimmer'
  },
  {
    value: 'mechanical door repairer',
    text: 'Mechanical door repairer'
  },
  {
    value: 'mechanical drafter',
    text: 'Mechanical drafter'
  },
  {
    value: 'mechanical engineer',
    text: 'Mechanical engineer'
  },
  {
    value: 'mechanical engineering technician',
    text: 'Mechanical engineering technician'
  },
  {
    value: 'mediator',
    text: 'Mediator'
  },
  {
    value: 'medical appliance technician',
    text: 'Medical appliance technician'
  },
  {
    value: 'medical assistant',
    text: 'Medical assistant'
  },
  {
    value: 'medical equipment preparer',
    text: 'Medical equipment preparer'
  },
  {
    value: 'medical equipment repairer',
    text: 'Medical equipment repairer'
  },
  {
    value: 'medical laboratory technician',
    text: 'Medical laboratory technician'
  },
  {
    value: 'medical laboratory technologist',
    text: 'Medical laboratory technologist'
  },
  {
    value: 'medical records technician',
    text: 'Medical records technician'
  },
  {
    value: 'medical scientist',
    text: 'Medical scientist'
  },
  {
    value: 'medical secretary',
    text: 'Medical secretary'
  },
  {
    value: 'medical services manager',
    text: 'Medical services manager'
  },
  {
    value: 'medical transcriptionist',
    text: 'Medical transcriptionist'
  },
  {
    value: 'meeting planner',
    text: 'Meeting planner'
  },
  {
    value: 'mental health counselor',
    text: 'Mental health counselor'
  },
  {
    value: 'mental health social worker',
    text: 'Mental health social worker'
  },
  {
    value: 'merchandise displayer',
    text: 'Merchandise displayer'
  },
  {
    value: 'messenger',
    text: 'Messenger'
  },
  {
    value: 'metal caster',
    text: 'Metal caster'
  },
  {
    value: 'metal patternmaker',
    text: 'Metal patternmaker'
  },
  {
    value: 'metal pickling operator',
    text: 'Metal pickling operator'
  },
  {
    value: 'metal pourer',
    text: 'Metal pourer'
  },
  {
    value: 'metal worker',
    text: 'Metal worker'
  },
  {
    value: 'metal-refining furnace operator',
    text: 'Metal-refining furnace operator'
  },
  {
    value: 'metal-refining furnace tender',
    text: 'Metal-refining furnace tender'
  },
  {
    value: 'meter reader',
    text: 'Meter reader'
  },
  {
    value: 'microbiologist',
    text: 'Microbiologist'
  },
  {
    value: 'middle school teacher',
    text: 'Middle school teacher'
  },
  {
    value: 'milling machine setter',
    text: 'Milling machine setter'
  },
  {
    value: 'millwright',
    text: 'Millwright'
  },
  {
    value: 'mine cutting machine operator',
    text: 'Mine cutting machine operator'
  },
  {
    value: 'mine shuttle car operator',
    text: 'Mine shuttle car operator'
  },
  {
    value: 'mining engineer',
    text: 'Mining engineer'
  },
  {
    value: 'mining safety engineer',
    text: 'Mining safety engineer'
  },
  {
    value: 'mining safety inspector',
    text: 'Mining safety inspector'
  },
  {
    value: 'mining service unit operator',
    text: 'Mining service unit operator'
  },
  {
    value: 'mixing machine setter',
    text: 'Mixing machine setter'
  },
  {
    value: 'mobile heavy equipment mechanic',
    text: 'Mobile heavy equipment mechanic'
  },
  {
    value: 'mobile home installer',
    text: 'Mobile home installer'
  },
  {
    value: 'model maker',
    text: 'Model maker'
  },
  {
    value: 'model',
    text: 'Model'
  },
  {
    value: 'molder',
    text: 'Molder'
  },
  {
    value: 'mortician',
    text: 'Mortician'
  },
  {
    value: 'motel desk clerk',
    text: 'Motel desk clerk'
  },
  {
    value: 'motion picture projectionist',
    text: 'Motion picture projectionist'
  },
  {
    value: 'motorboat mechanic',
    text: 'Motorboat mechanic'
  },
  {
    value: 'motorboat operator',
    text: 'Motorboat operator'
  },
  {
    value: 'motorboat service technician',
    text: 'Motorboat service technician'
  },
  {
    value: 'motorcycle mechanic',
    text: 'Motorcycle mechanic'
  },
  {
    value: 'movers',
    text: 'Movers'
  },
  {
    value: 'multimedia artist',
    text: 'Multimedia artist'
  },
  {
    value: 'museum technician',
    text: 'Museum technician'
  },
  {
    value: 'music director',
    text: 'Music director'
  },
  {
    value: 'music teacher',
    text: 'Music teacher'
  },
  {
    value: 'musical instrument repairer',
    text: 'Musical instrument repairer'
  },
  {
    value: 'musician',
    text: 'Musician'
  },
  {
    value: 'natural sciences manager',
    text: 'Natural sciences manager'
  },
  {
    value: 'naval architect',
    text: 'Naval architect'
  },
  {
    value: 'network systems administrator',
    text: 'Network systems administrator'
  },
  {
    value: 'new accounts clerk',
    text: 'New accounts clerk'
  },
  {
    value: 'news vendor',
    text: 'News vendor'
  },
  {
    value: 'nonfarm animal caretaker',
    text: 'Nonfarm animal caretaker'
  },
  {
    value: 'nuclear engineer',
    text: 'Nuclear engineer'
  },
  {
    value: 'nuclear medicine technologist',
    text: 'Nuclear medicine technologist'
  },
  {
    value: 'nuclear power reactor operator',
    text: 'Nuclear power reactor operator'
  },
  {
    value: 'nuclear technician',
    text: 'Nuclear technician'
  },
  {
    value: 'nursing aide',
    text: 'Nursing aide'
  },
  {
    value: 'nursing instructor',
    text: 'Nursing instructor'
  },
  {
    value: 'nursing teacher',
    text: 'Nursing teacher'
  },
  {
    value: 'nutritionist',
    text: 'Nutritionist'
  },
  {
    value: 'obstetrician',
    text: 'Obstetrician'
  },
  {
    value: 'occupational health and safety specialist',
    text: 'Occupational health and safety specialist'
  },
  {
    value: 'occupational health and safety technician',
    text: 'Occupational health and safety technician'
  },
  {
    value: 'occupational therapist',
    text: 'Occupational therapist'
  },
  {
    value: 'occupational therapy aide',
    text: 'Occupational therapy aide'
  },
  {
    value: 'occupational therapy assistant',
    text: 'Occupational therapy assistant'
  },
  {
    value: 'offbearer',
    text: 'Offbearer'
  },
  {
    value: 'office clerk',
    text: 'Office clerk'
  },
  {
    value: 'office machine operator',
    text: 'Office machine operator'
  },
  {
    value: 'operating engineer',
    text: 'Operating engineer'
  },
  {
    value: 'operations manager',
    text: 'Operations manager'
  },
  {
    value: 'operations research analyst',
    text: 'Operations research analyst'
  },
  {
    value: 'ophthalmic laboratory technician',
    text: 'Ophthalmic laboratory technician'
  },
  {
    value: 'optician',
    text: 'Optician'
  },
  {
    value: 'optometrist',
    text: 'Optometrist'
  },
  {
    value: 'oral surgeon',
    text: 'Oral surgeon'
  },
  {
    value: 'order clerk',
    text: 'Order clerk'
  },
  {
    value: 'order filler',
    text: 'Order filler'
  },
  {
    value: 'orderly',
    text: 'Orderly'
  },
  {
    value: 'ordnance handling expert',
    text: 'Ordnance handling expert'
  },
  {
    value: 'orthodontist',
    text: 'Orthodontist'
  },
  {
    value: 'orthotist',
    text: 'Orthotist'
  },
  {
    value: 'outdoor power equipment mechanic',
    text: 'Outdoor power equipment mechanic'
  },
  {
    value: 'oven operator',
    text: 'Oven operator'
  },
  {
    value: 'packaging machine operator',
    text: 'Packaging machine operator'
  },
  {
    value: 'painter ',
    text: 'Painter '
  },
  {
    value: 'painting worker',
    text: 'Painting worker'
  },
  {
    value: 'paper goods machine setter',
    text: 'Paper goods machine setter'
  },
  {
    value: 'paperhanger',
    text: 'Paperhanger'
  },
  {
    value: 'paralegal',
    text: 'Paralegal'
  },
  {
    value: 'paramedic',
    text: 'Paramedic'
  },
  {
    value: 'parking enforcement worker',
    text: 'Parking enforcement worker'
  },
  {
    value: 'parking lot attendant',
    text: 'Parking lot attendant'
  },
  {
    value: 'parts salesperson',
    text: 'Parts salesperson'
  },
  {
    value: 'paving equipment operator',
    text: 'Paving equipment operator'
  },
  {
    value: 'payroll clerk',
    text: 'Payroll clerk'
  },
  {
    value: 'pediatrician',
    text: 'Pediatrician'
  },
  {
    value: 'pedicurist',
    text: 'Pedicurist'
  },
  {
    value: 'personal care aide',
    text: 'Personal care aide'
  },
  {
    value: 'personal chef',
    text: 'Personal chef'
  },
  {
    value: 'personal financial advisor',
    text: 'Personal financial advisor'
  },
  {
    value: 'personal trainer',
    text: 'Personal trainer'
  },
  {
    value: 'pest control worker',
    text: 'Pest control worker'
  },
  {
    value: 'pesticide applicator',
    text: 'Pesticide applicator'
  },
  {
    value: 'pesticide handler',
    text: 'Pesticide handler'
  },
  {
    value: 'pesticide sprayer',
    text: 'Pesticide sprayer'
  },
  {
    value: 'petroleum engineer',
    text: 'Petroleum engineer'
  },
  {
    value: 'petroleum gauger',
    text: 'Petroleum gauger'
  },
  {
    value: 'petroleum pump system operator',
    text: 'Petroleum pump system operator'
  },
  {
    value: 'petroleum refinery operator',
    text: 'Petroleum refinery operator'
  },
  {
    value: 'petroleum technician',
    text: 'Petroleum technician'
  },
  {
    value: 'pharmacist',
    text: 'Pharmacist'
  },
  {
    value: 'pharmacy aide',
    text: 'Pharmacy aide'
  },
  {
    value: 'pharmacy technician',
    text: 'Pharmacy technician'
  },
  {
    value: 'philosophy teacher',
    text: 'Philosophy teacher'
  },
  {
    value: 'photogrammetrist',
    text: 'Photogrammetrist'
  },
  {
    value: 'photographer',
    text: 'Photographer'
  },
  {
    value: 'photographic process worker',
    text: 'Photographic process worker'
  },
  {
    value: 'photographic processing machine operator',
    text: 'Photographic processing machine operator'
  },
  {
    value: 'physical therapist aide',
    text: 'Physical therapist aide'
  },
  {
    value: 'physical therapist assistant',
    text: 'Physical therapist assistant'
  },
  {
    value: 'physical therapist',
    text: 'Physical therapist'
  },
  {
    value: 'physician assistant',
    text: 'Physician assistant'
  },
  {
    value: 'physician',
    text: 'Physician'
  },
  {
    value: 'physicist',
    text: 'Physicist'
  },
  {
    value: 'physics teacher',
    text: 'Physics teacher'
  },
  {
    value: 'pile-driver operator',
    text: 'Pile-driver operator'
  },
  {
    value: 'pipefitter',
    text: 'Pipefitter'
  },
  {
    value: 'pipelayer',
    text: 'Pipelayer'
  },
  {
    value: 'planing machine operator',
    text: 'Planing machine operator'
  },
  {
    value: 'planning clerk',
    text: 'Planning clerk'
  },
  {
    value: 'plant operator',
    text: 'Plant operator'
  },
  {
    value: 'plant scientist',
    text: 'Plant scientist'
  },
  {
    value: 'plasterer',
    text: 'Plasterer'
  },
  {
    value: 'plastic patternmaker',
    text: 'Plastic patternmaker'
  },
  {
    value: 'plastic worker',
    text: 'Plastic worker'
  },
  {
    value: 'plumber',
    text: 'Plumber'
  },
  {
    value: 'podiatrist',
    text: 'Podiatrist'
  },
  {
    value: 'police dispatcher',
    text: 'Police dispatcher'
  },
  {
    value: 'police officer',
    text: 'Police officer'
  },
  {
    value: 'policy processing clerk',
    text: 'Policy processing clerk'
  },
  {
    value: 'political science teacher',
    text: 'Political science teacher'
  },
  {
    value: 'political scientist',
    text: 'Political scientist'
  },
  {
    value: 'postal service clerk',
    text: 'Postal service clerk'
  },
  {
    value: 'postal service mail carrier',
    text: 'Postal service mail carrier'
  },
  {
    value: 'postal service mail processing machine operator',
    text: 'Postal service mail processing machine operator'
  },
  {
    value: 'postal service mail processor',
    text: 'Postal service mail processor'
  },
  {
    value: 'postal service mail sorter',
    text: 'Postal service mail sorter'
  },
  {
    value: 'postmaster',
    text: 'Postmaster'
  },
  {
    value: 'postsecondary teacher',
    text: 'Postsecondary teacher'
  },
  {
    value: 'poultry cutter',
    text: 'Poultry cutter'
  },
  {
    value: 'poultry trimmer',
    text: 'Poultry trimmer'
  },
  {
    value: 'power dispatcher',
    text: 'Power dispatcher'
  },
  {
    value: 'power distributor',
    text: 'Power distributor'
  },
  {
    value: 'power plant operator',
    text: 'Power plant operator'
  },
  {
    value: 'power tool repairer',
    text: 'Power tool repairer'
  },
  {
    value: 'precious stone worker',
    text: 'Precious stone worker'
  },
  {
    value: 'precision instrument repairer',
    text: 'Precision instrument repairer'
  },
  {
    value: 'prepress technician',
    text: 'Prepress technician'
  },
  {
    value: 'preschool teacher',
    text: 'Preschool teacher'
  },
  {
    value: 'priest',
    text: 'Priest'
  },
  {
    value: 'print binding worker',
    text: 'Print binding worker'
  },
  {
    value: 'printing press operator',
    text: 'Printing press operator'
  },
  {
    value: 'private detective',
    text: 'Private detective'
  },
  {
    value: 'probation officer',
    text: 'Probation officer'
  },
  {
    value: 'procurement clerk',
    text: 'Procurement clerk'
  },
  {
    value: 'producer',
    text: 'Producer'
  },
  {
    value: 'product promoter',
    text: 'Product promoter'
  },
  {
    value: 'product manager',
    text: 'Product manager'
  },
  {
    value: 'production clerk',
    text: 'Production clerk'
  },
  {
    value: 'production occupation',
    text: 'Production occupation'
  },
  {
    value: 'proofreader',
    text: 'Proofreader'
  },
  {
    value: 'property manager',
    text: 'Property manager'
  },
  {
    value: 'prosthetist',
    text: 'Prosthetist'
  },
  {
    value: 'prosthodontist',
    text: 'Prosthodontist'
  },
  {
    value: 'psychiatric aide',
    text: 'Psychiatric aide'
  },
  {
    value: 'psychiatric technician',
    text: 'Psychiatric technician'
  },
  {
    value: 'psychiatrist',
    text: 'Psychiatrist'
  },
  {
    value: 'psychologist',
    text: 'Psychologist'
  },
  {
    value: 'psychology teacher',
    text: 'Psychology teacher'
  },
  {
    value: 'public relations manager',
    text: 'Public relations manager'
  },
  {
    value: 'public relations specialist',
    text: 'Public relations specialist'
  },
  {
    value: 'pump operator',
    text: 'Pump operator'
  },
  {
    value: 'purchasing agent',
    text: 'Purchasing agent'
  },
  {
    value: 'purchasing manager',
    text: 'Purchasing manager'
  },
  {
    value: 'radiation therapist',
    text: 'Radiation therapist'
  },
  {
    value: 'radio announcer',
    text: 'Radio announcer'
  },
  {
    value: 'radio equipment installer',
    text: 'Radio equipment installer'
  },
  {
    value: 'radio operator',
    text: 'Radio operator'
  },
  {
    value: 'radiologic technician',
    text: 'Radiologic technician'
  },
  {
    value: 'radiologic technologist',
    text: 'Radiologic technologist'
  },
  {
    value: 'rail car repairer',
    text: 'Rail car repairer'
  },
  {
    value: 'rail transportation worker',
    text: 'Rail transportation worker'
  },
  {
    value: 'rail yard engineer',
    text: 'Rail yard engineer'
  },
  {
    value: 'rail-track laying equipment operator',
    text: 'Rail-track laying equipment operator'
  },
  {
    value: 'railroad brake operator',
    text: 'Railroad brake operator'
  },
  {
    value: 'railroad conductor',
    text: 'Railroad conductor'
  },
  {
    value: 'railroad police',
    text: 'Railroad police'
  },
  {
    value: 'rancher',
    text: 'Rancher'
  },
  {
    value: 'real estate appraiser',
    text: 'Real estate appraiser'
  },
  {
    value: 'real estate broker',
    text: 'Real estate broker'
  },
  {
    value: 'real estate manager',
    text: 'Real estate manager'
  },
  {
    value: 'real estate sales agent',
    text: 'Real estate sales agent'
  },
  {
    value: 'receiving clerk',
    text: 'Receiving clerk'
  },
  {
    value: 'receptionist',
    text: 'Receptionist'
  },
  {
    value: 'record clerk',
    text: 'Record clerk'
  },
  {
    value: 'recreation teacher',
    text: 'Recreation teacher'
  },
  {
    value: 'recreation worker',
    text: 'Recreation worker'
  },
  {
    value: 'recreational therapist',
    text: 'Recreational therapist'
  },
  {
    value: 'recreational vehicle service technician',
    text: 'Recreational vehicle service technician'
  },
  {
    value: 'recyclable material collector',
    text: 'Recyclable material collector'
  },
  {
    value: 'referee',
    text: 'Referee'
  },
  {
    value: 'refractory materials repairer',
    text: 'Refractory materials repairer'
  },
  {
    value: 'refrigeration installer',
    text: 'Refrigeration installer'
  },
  {
    value: 'refrigeration mechanic',
    text: 'Refrigeration mechanic'
  },
  {
    value: 'refuse collector',
    text: 'Refuse collector'
  },
  {
    value: 'regional planner',
    text: 'Regional planner'
  },
  {
    value: 'registered nurse',
    text: 'Registered nurse'
  },
  {
    value: 'rehabilitation counselor',
    text: 'Rehabilitation counselor'
  },
  {
    value: 'reinforcing iron worker',
    text: 'Reinforcing iron worker'
  },
  {
    value: 'reinforcing rebar worker',
    text: 'Reinforcing rebar worker'
  },
  {
    value: 'religion teacher',
    text: 'Religion teacher'
  },
  {
    value: 'religious activities director',
    text: 'Religious activities director'
  },
  {
    value: 'religious worker',
    text: 'Religious worker'
  },
  {
    value: 'rental clerk',
    text: 'Rental clerk'
  },
  {
    value: 'repair worker',
    text: 'Repair worker'
  },
  {
    value: 'reporter',
    text: 'Reporter'
  },
  {
    value: 'residential advisor',
    text: 'Residential advisor'
  },
  {
    value: 'resort desk clerk',
    text: 'Resort desk clerk'
  },
  {
    value: 'respiratory therapist',
    text: 'Respiratory therapist'
  },
  {
    value: 'respiratory therapy technician',
    text: 'Respiratory therapy technician'
  },
  {
    value: 'retail buyer',
    text: 'Retail buyer'
  },
  {
    value: 'retail salesperson',
    text: 'Retail salesperson'
  },
  {
    value: 'retired',
    text: 'Retired'
  },
  {
    value: 'revenue agent',
    text: 'Revenue agent'
  },
  {
    value: 'rigger',
    text: 'Rigger'
  },
  {
    value: 'rock splitter',
    text: 'Rock splitter'
  },
  {
    value: 'rolling machine tender',
    text: 'Rolling machine tender'
  },
  {
    value: 'roof bolter',
    text: 'Roof bolter'
  },
  {
    value: 'roofer',
    text: 'Roofer'
  },
  {
    value: 'rotary drill operator',
    text: 'Rotary drill operator'
  },
  {
    value: 'roustabout',
    text: 'Roustabout'
  },
  {
    value: 'safe repairer',
    text: 'Safe repairer'
  },
  {
    value: 'sailor',
    text: 'Sailor'
  },
  {
    value: 'sales engineer',
    text: 'Sales engineer'
  },
  {
    value: 'sales manager',
    text: 'Sales manager'
  },
  {
    value: 'sales representative',
    text: 'Sales representative'
  },
  {
    value: 'sampler',
    text: 'Sampler'
  },
  {
    value: 'sawing machine operator',
    text: 'Sawing machine operator'
  },
  {
    value: 'scaler',
    text: 'Scaler'
  },
  {
    value: 'school bus driver',
    text: 'School bus driver'
  },
  {
    value: 'school psychologist',
    text: 'School psychologist'
  },
  {
    value: 'school social worker',
    text: 'School social worker'
  },
  {
    value: 'scout leader',
    text: 'Scout leader'
  },
  {
    value: 'sculptor',
    text: 'Sculptor'
  },
  {
    value: 'secondary education teacher',
    text: 'Secondary education teacher'
  },
  {
    value: 'secondary school teacher',
    text: 'Secondary school teacher'
  },
  {
    value: 'secretary',
    text: 'Secretary'
  },
  {
    value: 'securities sales agent',
    text: 'Securities sales agent'
  },
  {
    value: 'security guard',
    text: 'Security guard'
  },
  {
    value: 'security system installer',
    text: 'Security system installer'
  },
  {
    value: 'segmental paver',
    text: 'Segmental paver'
  },
  {
    value: 'self-enrichment education teacher',
    text: 'Self-enrichment education teacher'
  },
  {
    value: 'semiconductor processor',
    text: 'Semiconductor processor'
  },
  {
    value: 'septic tank servicer',
    text: 'Septic tank servicer'
  },
  {
    value: 'set designer',
    text: 'Set designer'
  },
  {
    value: 'sewer pipe cleaner',
    text: 'Sewer pipe cleaner'
  },
  {
    value: 'sewing machine operator',
    text: 'Sewing machine operator'
  },
  {
    value: 'shampooer',
    text: 'Shampooer'
  },
  {
    value: 'shaper',
    text: 'Shaper'
  },
  {
    value: 'sheet metal worker',
    text: 'Sheet metal worker'
  },
  {
    value: "sheriff's patrol officer",
    text: "Sheriff's patrol officer"
  },
  {
    value: 'ship captain',
    text: 'Ship captain'
  },
  {
    value: 'ship engineer',
    text: 'Ship engineer'
  },
  {
    value: 'ship loader',
    text: 'Ship loader'
  },
  {
    value: 'shipmate',
    text: 'Shipmate'
  },
  {
    value: 'shipping clerk',
    text: 'Shipping clerk'
  },
  {
    value: 'shoe machine operator',
    text: 'Shoe machine operator'
  },
  {
    value: 'shoe worker',
    text: 'Shoe worker'
  },
  {
    value: 'short order cook',
    text: 'Short order cook'
  },
  {
    value: 'signal operator',
    text: 'Signal operator'
  },
  {
    value: 'signal repairer',
    text: 'Signal repairer'
  },
  {
    value: 'singer',
    text: 'Singer'
  },
  {
    value: 'ski patrol',
    text: 'Ski patrol'
  },
  {
    value: 'skincare specialist',
    text: 'Skincare specialist'
  },
  {
    value: 'slaughterer',
    text: 'Slaughterer'
  },
  {
    value: 'slicing machine tender',
    text: 'Slicing machine tender'
  },
  {
    value: 'slot supervisor',
    text: 'Slot supervisor'
  },
  {
    value: 'social science research assistant',
    text: 'Social science research assistant'
  },
  {
    value: 'social sciences teacher',
    text: 'Social sciences teacher'
  },
  {
    value: 'social scientist',
    text: 'Social scientist'
  },
  {
    value: 'social service assistant',
    text: 'Social service assistant'
  },
  {
    value: 'social service manager',
    text: 'Social service manager'
  },
  {
    value: 'social work teacher',
    text: 'Social work teacher'
  },
  {
    value: 'social worker',
    text: 'Social worker'
  },
  {
    value: 'sociologist',
    text: 'Sociologist'
  },
  {
    value: 'sociology teacher',
    text: 'Sociology teacher'
  },
  {
    value: 'software developer',
    text: 'Software developer'
  },
  {
    value: 'software engineer',
    text: 'Software engineer'
  },
  {
    value: 'soil scientist',
    text: 'Soil scientist'
  },
  {
    value: 'solderer',
    text: 'Solderer'
  },
  {
    value: 'sorter',
    text: 'Sorter'
  },
  {
    value: 'sound engineering technician',
    text: 'Sound engineering technician'
  },
  {
    value: 'space scientist',
    text: 'Space scientist'
  },
  {
    value: 'special education teacher',
    text: 'Special education teacher'
  },
  {
    value: 'speech-language pathologist',
    text: 'Speech-language pathologist'
  },
  {
    value: 'sports book runner',
    text: 'Sports book runner'
  },
  {
    value: 'sports entertainer',
    text: 'Sports entertainer'
  },
  {
    value: 'sports performer',
    text: 'Sports performer'
  },
  {
    value: 'stationary engineer',
    text: 'Stationary engineer'
  },
  {
    value: 'statistical assistant',
    text: 'Statistical assistant'
  },
  {
    value: 'statistician',
    text: 'Statistician'
  },
  {
    value: 'steamfitter',
    text: 'Steamfitter'
  },
  {
    value: 'stock clerk',
    text: 'Stock clerk'
  },
  {
    value: 'stock mover',
    text: 'Stock mover'
  },
  {
    value: 'stonemason',
    text: 'Stonemason'
  },
  {
    value: 'street vendor',
    text: 'Street vendor'
  },
  {
    value: 'streetcar operator',
    text: 'Streetcar operator'
  },
  {
    value: 'structural iron worker',
    text: 'Structural iron worker'
  },
  {
    value: 'structural metal fabricator',
    text: 'Structural metal fabricator'
  },
  {
    value: 'structural metal fitter',
    text: 'Structural metal fitter'
  },
  {
    value: 'structural steel worker',
    text: 'Structural steel worker'
  },
  {
    value: 'stucco mason',
    text: 'Stucco mason'
  },
  {
    value: 'substance abuse counselor',
    text: 'Substance abuse counselor'
  },
  {
    value: 'substance abuse social worker',
    text: 'Substance abuse social worker'
  },
  {
    value: 'subway operator',
    text: 'Subway operator'
  },
  {
    value: 'surfacing equipment operator',
    text: 'Surfacing equipment operator'
  },
  {
    value: 'surgeon',
    text: 'Surgeon'
  },
  {
    value: 'surgical technologist',
    text: 'Surgical technologist'
  },
  {
    value: 'survey researcher',
    text: 'Survey researcher'
  },
  {
    value: 'surveying technician',
    text: 'Surveying technician'
  },
  {
    value: 'surveyor',
    text: 'Surveyor'
  },
  {
    value: 'switch operator',
    text: 'Switch operator'
  },
  {
    value: 'switchboard operator',
    text: 'Switchboard operator'
  },
  {
    value: 'tailor',
    text: 'Tailor'
  },
  {
    value: 'tamping equipment operator',
    text: 'Tamping equipment operator'
  },
  {
    value: 'tank car loader',
    text: 'Tank car loader'
  },
  {
    value: 'taper',
    text: 'Taper'
  },
  {
    value: 'tax collector',
    text: 'Tax collector'
  },
  {
    value: 'tax examiner',
    text: 'Tax examiner'
  },
  {
    value: 'tax preparer',
    text: 'Tax preparer'
  },
  {
    value: 'taxi driver',
    text: 'Taxi driver'
  },
  {
    value: 'teacher assistant',
    text: 'Teacher assistant'
  },
  {
    value: 'teacher',
    text: 'Teacher'
  },
  {
    value: 'team assembler',
    text: 'Team assembler'
  },
  {
    value: 'technical writer',
    text: 'Technical writer'
  },
  {
    value: 'telecommunications equipment installer',
    text: 'Telecommunications equipment installer'
  },
  {
    value: 'telemarketer',
    text: 'Telemarketer'
  },
  {
    value: 'telephone operator',
    text: 'Telephone operator'
  },
  {
    value: 'television announcer',
    text: 'Television announcer'
  },
  {
    value: 'teller',
    text: 'Teller'
  },
  {
    value: 'terrazzo finisher',
    text: 'Terrazzo finisher'
  },
  {
    value: 'terrazzo worker',
    text: 'Terrazzo worker'
  },
  {
    value: 'tester',
    text: 'Tester'
  },
  {
    value: 'textile bleaching operator',
    text: 'Textile bleaching operator'
  },
  {
    value: 'textile cutting machine setter',
    text: 'Textile cutting machine setter'
  },
  {
    value: 'textile knitting machine setter',
    text: 'Textile knitting machine setter'
  },
  {
    value: 'textile presser',
    text: 'Textile presser'
  },
  {
    value: 'textile worker',
    text: 'Textile worker'
  },
  {
    value: 'therapist',
    text: 'Therapist'
  },
  {
    value: 'ticket agent',
    text: 'Ticket agent'
  },
  {
    value: 'ticket taker',
    text: 'Ticket taker'
  },
  {
    value: 'tile setter',
    text: 'Tile setter'
  },
  {
    value: 'timekeeping clerk',
    text: 'Timekeeping clerk'
  },
  {
    value: 'timing device assembler',
    text: 'Timing device assembler'
  },
  {
    value: 'tire builder',
    text: 'Tire builder'
  },
  {
    value: 'tire changer',
    text: 'Tire changer'
  },
  {
    value: 'tire repairer',
    text: 'Tire repairer'
  },
  {
    value: 'title abstractor',
    text: 'Title abstractor'
  },
  {
    value: 'title examiner',
    text: 'Title examiner'
  },
  {
    value: 'title searcher',
    text: 'Title searcher'
  },
  {
    value: 'tobacco roasting machine operator',
    text: 'Tobacco roasting machine operator'
  },
  {
    value: 'tool filer',
    text: 'Tool filer'
  },
  {
    value: 'tool grinder',
    text: 'Tool grinder'
  },
  {
    value: 'tool maker',
    text: 'Tool maker'
  },
  {
    value: 'tool sharpener',
    text: 'Tool sharpener'
  },
  {
    value: 'tour guide',
    text: 'Tour guide'
  },
  {
    value: 'tower equipment installer',
    text: 'Tower equipment installer'
  },
  {
    value: 'tower operator',
    text: 'Tower operator'
  },
  {
    value: 'track switch repairer',
    text: 'Track switch repairer'
  },
  {
    value: 'tractor operator',
    text: 'Tractor operator'
  },
  {
    value: 'tractor-trailer truck driver',
    text: 'Tractor-trailer truck driver'
  },
  {
    value: 'traffic clerk',
    text: 'Traffic clerk'
  },
  {
    value: 'traffic technician',
    text: 'Traffic technician'
  },
  {
    value: 'training and development manager',
    text: 'Training and development manager'
  },
  {
    value: 'training and development specialist',
    text: 'Training and development specialist'
  },
  {
    value: 'transit police',
    text: 'Transit police'
  },
  {
    value: 'translator',
    text: 'Translator'
  },
  {
    value: 'transportation equipment painter',
    text: 'Transportation equipment painter'
  },
  {
    value: 'transportation inspector',
    text: 'Transportation inspector'
  },
  {
    value: 'transportation security screener',
    text: 'Transportation security screener'
  },
  {
    value: 'transportation worker',
    text: 'Transportation worker'
  },
  {
    value: 'trapper',
    text: 'Trapper'
  },
  {
    value: 'travel agent',
    text: 'Travel agent'
  },
  {
    value: 'travel clerk',
    text: 'Travel clerk'
  },
  {
    value: 'travel guide',
    text: 'Travel guide'
  },
  {
    value: 'tree pruner',
    text: 'Tree pruner'
  },
  {
    value: 'tree trimmer',
    text: 'Tree trimmer'
  },
  {
    value: 'trimmer',
    text: 'Trimmer'
  },
  {
    value: 'truck loader',
    text: 'Truck loader'
  },
  {
    value: 'truck mechanic',
    text: 'Truck mechanic'
  },
  {
    value: 'tuner',
    text: 'Tuner'
  },
  {
    value: 'turning machine tool operator',
    text: 'Turning machine tool operator'
  },
  {
    value: 'tutor',
    text: 'Tutor'
  },
  {
    value: 'typist',
    text: 'Typist'
  },
  {
    value: 'umpire',
    text: 'Umpire'
  },
  {
    value: 'undertaker',
    text: 'Undertaker'
  },
  {
    value: 'upholsterer',
    text: 'Upholsterer'
  },
  {
    value: 'urban planner',
    text: 'Urban planner'
  },
  {
    value: 'usher',
    text: 'Usher'
  },
  {
    value: 'ux designer',
    text: 'UX designer'
  },
  {
    value: 'valve installer',
    text: 'Valve installer'
  },
  {
    value: 'vending machine servicer',
    text: 'Vending machine servicer'
  },
  {
    value: 'veterinarian',
    text: 'Veterinarian'
  },
  {
    value: 'veterinary assistant',
    text: 'Veterinary assistant'
  },
  {
    value: 'veterinary technician',
    text: 'Veterinary technician'
  },
  {
    value: 'vocational counselor',
    text: 'Vocational counselor'
  },
  {
    value: 'vocational education teacher',
    text: 'Vocational education teacher'
  },
  {
    value: 'waiter',
    text: 'Waiter'
  },
  {
    value: 'waitress',
    text: 'Waitress'
  },
  {
    value: 'watch repairer',
    text: 'Watch repairer'
  },
  {
    value: 'water treatment plant operator',
    text: 'Water treatment plant operator'
  },
  {
    value: 'weaving machine setter',
    text: 'Weaving machine setter'
  },
  {
    value: 'web developer',
    text: 'Web developer'
  },
  {
    value: 'weigher',
    text: 'Weigher'
  },
  {
    value: 'welder',
    text: 'Welder'
  },
  {
    value: 'wellhead pumper',
    text: 'Wellhead pumper'
  },
  {
    value: 'wholesale buyer',
    text: 'Wholesale buyer'
  },
  {
    value: 'wildlife biologist',
    text: 'Wildlife biologist'
  },
  {
    value: 'window trimmer',
    text: 'Window trimmer'
  },
  {
    value: 'wood patternmaker',
    text: 'Wood patternmaker'
  },
  {
    value: 'woodworker',
    text: 'Woodworker'
  },
  {
    value: 'word processor',
    text: 'Word processor'
  },
  {
    value: 'writer',
    text: 'Writer'
  },
  {
    value: 'yardmaster',
    text: 'Yardmaster'
  },
  {
    value: 'zoologist',
    text: 'Zoologist'
  }
];
