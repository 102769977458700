<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-form class="p-5" @submit.prevent="submitForm">
    <template v-if="!isFullForm">
      <b-row>
        <b-row>
          <b-col cols="12"
            ><label
              >Now upload a Statement of your PRSI / Super / Social Insurance / Social Security payments. It’s easy to
              get!<br />
              <b class="text-red"
                >Watch this short <a href="https://xpen.uk/socins" target="_blank">Explanation Video</a></b
              >
            </label></b-col
          >
          <b-col cols="12">
            <div class="input-container mt-3">
              <input
                type="file"
                class="form-control"
                @change="onChangeFileUploadStatements($event)"
                :disabled="isLoading"
                accept="application/pdf, application/msword, image/png, image/jpeg, image/jpg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                max-size="10000"
                placeholder="Choose PDF File"
              />
              <!-- <b-form-text>
                Note: If you have multiple PDF documents, combine them using this
                <a href="https://acrobat.adobe.com/link/acrobat/combine-pdf" target="_blank">free tool</a>
              </b-form-text> -->
            </div>
          </b-col>
        </b-row>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="input-container">
            <b-form-checkbox
              v-model="agentAuthorisation.basic"
              name="basic"
              :class="v$.agentAuthorisation.basic.$error ? 'is-invalid' : ''"
              :disabled="isLoading"
            >
              I understand I need to provide this document for my application to be complete
            </b-form-checkbox>
            <div v-for="error of v$.agentAuthorisation.basic.$errors" class="invalid-feedback" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="input-container">
            <b-form-checkbox
              v-model="agentAuthorisation.termsAndConditions"
              name="termsAndConditions"
              @input="setTouched('termsAndConditions')"
              :class="v$.agentAuthorisation.termsAndConditions.$error ? 'is-invalid' : ''"
              :disabled="isLoading"
            >
              I accept XtraPension’s
              <a href="https://xtrapension.com/terms" target="_blank">Terms & Conditions</a>
            </b-form-checkbox>
            <div
              v-for="error of v$.agentAuthorisation.termsAndConditions.$errors"
              class="invalid-feedback"
              :key="error.$uid"
              :disabled="isLoading"
            >
              {{ error.$message }}
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <p>Thank you for completing our questionnaire.</p>
          <p>
            Once you ‘submit’ your details below, our team will then review and revert if any problems or questions -
            usually within 5 working days.
          </p>
          <p>Please submit now.</p>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col>
          <p>
            <b class="text-red">TOP TIP!</b> You can submit the rest of your application now but we MUST get the above
            document to proceed<br />
            Send document via: <b>Email: </b>
            <a href="mailto:clients@xtrapension.com" target="_blank">clients@xtrapension.com</a> / <b>WhatsApp:</b> +353
            (0) 899 609 855
          </p>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12">
          <div class="input-container">
            <b-form-checkbox v-model="missingInfo.nino" name="nino" :disabled="isLoading">
              National Insurance Number (NINO)
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="input-container">
            <b-form-checkbox v-model="missingInfo.prsi" name="nino" :disabled="isLoading">
              PRSI (/Social Insurance/Security) Statement
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="input-container">
            <b-form-checkbox v-model="missingInfo.lastUKEmpl" name="nino" :disabled="isLoading">
              Last UK Employer Details
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="input-container">
            <b-form-checkbox v-model="missingInfo.workAfterUK" name="nino" :disabled="isLoading">
              Work AFTER UK - Employment Work History - Particularly since 2006 (when they left UK) to NOW!
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="input-container">
            <b-form-checkbox v-model="missingInfo.relationship" name="nino" :disabled="isLoading">
              Date of Divorce / Bereavement / Marriage (as applicable)
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-form>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { toast } from 'vue3-toastify';
export default {
  name: 'DocumentsStep',
  props: {
    documents: {
      type: Object,
      required: true
    },
    personal: {
      type: Object,
      required: true
    },
    agentAuthorisation: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    isFullForm: {
      type: Boolean,
      default: false
    },
    missingInfo: {
      type: Object
    },
    onSubmit: { type: Function, required: true }
  },
  data() {
    return {
      isLargeStatementFile: false,
      v$: useVuelidate()
    };
  },
  validations() {
    return {
      documents: {},
      agentAuthorisation: {
        termsAndConditions: {
          requiredIf: requiredIf(function () {
            return !this.isFullForm;
          })
        },
        basic: {
          requiredIf: requiredIf(function () {
            return !this.isFullForm;
          })
        }
      }
    };
  },
  methods: {
    setTouched(theModel) {
      if (theModel === 'termsAndConditions' || theModel == 'all') {
        this.v$.agentAuthorisation.termsAndConditions.$touch();
      }
      if (theModel === 'basic' || theModel == 'all') {
        this.v$.agentAuthorisation.basic.$touch();
      }
    },
    async onChangeFileUploadStatements(event) {
      try {
        const selectedFile = event.target.files[0];
        // eslint-disable-next-line vue/no-mutating-props
        this.documents.statements = await this.convertBlobToBase64(selectedFile);
        if (selectedFile?.size > 10000000) {
          this.isLargeStatementFile = true;
        } else this.isLargeStatementFile = false;
      } catch (error) {
        toast.error(error.message ?? 'Oops, Something Went Wrong');
        console.log(error);
      }
    },
    convertBlobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({
            base64: reader.result,
            fileName: blob.name
          });
        };
        reader.readAsDataURL(blob);
      });
    },
    submitForm() {
      this.setTouched('all');
      if (!this.v$.$invalid) {
        this.onSubmit();
      }
    }
  }
};
</script>
