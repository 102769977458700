<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-form class="p-5" @submit.prevent="submitForm">
    <template v-for="(work, index) in workAfterUK" :key="work">
      <b-row v-if="index > 0">
        <b-col>
          <div class="delete-icon-container">
            <img @click.prevent="deleteOtherNonUKWork(index)" src="@/assets/delete-icon.svg" class="delete-icon" />
            <span @click.prevent="deleteOtherNonUKWork(index)">Remove This</span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="6"
          ><label>26{{ formatNumberToLetter(index) }} - What Type of Work?</label></b-col
        >
        <b-col cols="12" lg="6">
          <div class="input-container">
            <b-form-select
              v-model="v$.workAfterUK.$model[index].afterLeftTheUK"
              @change="afterLeftTheUkChange(work)"
              :class="v$.workAfterUK.$each.$response.$errors[index].afterLeftTheUK.length ? 'is-invalid' : ''"
              :options="afterLeftTheUKOptions"
              :disabled="isLoading"
            ></b-form-select>
            <div
              v-for="error of v$.workAfterUK.$each.$response.$errors[index].afterLeftTheUK"
              class="invalid-feedback"
              :key="error.$uid"
            >
              {{ error.$message }}
            </div>
          </div>
        </b-col>
      </b-row>
      <template v-if="work.afterLeftTheUK">
        <b-row v-if="work.afterLeftTheUK == 'Employed'">
          <b-col cols="12" lg="6"
            ><label
              >26{{ formatNumberToLetter(index) }} -
              <template v-if="index === 0">What was the name of this 1st non-UK employer?</template>
              <template v-else>Other Non-UK Employer Name</template></label
            ></b-col
          >
          <b-col cols="12" lg="6">
            <div class="input-container">
              <b-form-input
                v-model="v$.workAfterUK.$model[index].nameOfYourEmployerAbroad"
                :class="
                  v$.workAfterUK.$each.$response.$errors[index].nameOfYourEmployerAbroad.length ? 'is-invalid' : ''
                "
                :disabled="isLoading"
                placeholder="Enter"
              ></b-form-input>
              <div
                v-for="error of v$.workAfterUK.$each.$response.$errors[index].nameOfYourEmployerAbroad"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="work.afterLeftTheUK == 'Employed'">
          <b-col cols="12" lg="6"
            ><label
              >26{{ formatNumberToLetter(index) }} -
              <template v-if="index === 0">What was your employers address abroad?</template>
              <template v-else>Their Address Abroad</template></label
            ></b-col
          >
          <b-col cols="12" lg="6">
            <b-row>
              <b-col cols="12" lg="6">
                <div class="input-container">
                  <label>Country:</label>
                  <b-form-select
                    v-model="v$.workAfterUK.$model[index].country"
                    :options="getCountryOptions()"
                    @change="countryChange(work)"
                    :class="v$.workAfterUK.$each.$response.$errors[index].country.length ? 'is-invalid' : ''"
                    :disabled="isLoading"
                  ></b-form-select>
                  <div
                    v-for="error of v$.workAfterUK.$each.$response.$errors[index].country"
                    class="invalid-feedback"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="6">
                <div class="input-container" v-if="getCountryStateOptions(work.country).length">
                  <label>County/State:</label>
                  <b-form-select
                    v-model="v$.workAfterUK.$model[index].state"
                    :class="v$.workAfterUK.$each.$response.$errors[index].state.length ? 'is-invalid' : ''"
                    :disabled="isLoading"
                    :options="getCountryStateOptions(work.country)"
                    placeholder="Enter County/State"
                  ></b-form-select>
                  <div
                    v-for="error of v$.workAfterUK.$each.$response.$errors[index].state"
                    class="invalid-feedback"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="12">
                <div class="input-container">
                  <label>Number & Street:</label>
                  <b-form-input
                    v-model="v$.workAfterUK.$model[index].street"
                    :class="v$.workAfterUK.$each.$response.$errors[index].street.length ? 'is-invalid' : ''"
                    :disabled="isLoading"
                    :formatter="capitalizeWords"
                    placeholder="Enter Number & Street"
                  ></b-form-input>
                  <div
                    v-for="error of v$.workAfterUK.$each.$response.$errors[index].street"
                    class="invalid-feedback"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="6">
                <div class="input-container">
                  <label>Town/District:</label>
                  <b-form-input
                    v-model="v$.workAfterUK.$model[index].city"
                    :class="v$.workAfterUK.$each.$response.$errors[index].city.length ? 'is-invalid' : ''"
                    :disabled="isLoading"
                    :formatter="capitalizeWords"
                    placeholder="Enter Town/District"
                  ></b-form-input>
                  <div
                    v-for="error of v$.workAfterUK.$each.$response.$errors[index].city"
                    class="invalid-feedback"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="6">
                <div class="input-container">
                  <label>Postcode</label>
                  <b-form-input
                    v-model="v$.workAfterUK.$model[index].postcode"
                    :class="v$.workAfterUK.$each.$response.$errors[index].postcode.length ? 'is-invalid' : ''"
                    :disabled="isLoading"
                    placeholder="Enter Postcode"
                  ></b-form-input>
                  <div
                    v-for="error of v$.workAfterUK.$each.$response.$errors[index].postcode"
                    class="invalid-feedback"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </div>
                  <b-form-text v-if="v$.workAfterUK.$model[index].country === 'GB'">
                    If you don't know, find your postcode here:
                    <a href="https://postcodefinder.net/" target="_blank">https://postcodefinder.net/</a><br />
                    If you commuted to work from Ireland to Northern Ireland and never had a UK address, please insert
                    the Irish address where you would have received your UK Government correspondence
                  </b-form-text>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6"
            ><label>26{{ formatNumberToLetter(index) }} - Start Date</label></b-col
          >
          <b-col cols="12" lg="6">
            <div class="input-container">
              <b-form-input
                v-model="v$.workAfterUK.$model[index].startDate"
                type="date"
                :class="v$.workAfterUK.$each.$response.$errors[index].startDate.length ? 'is-invalid' : ''"
                :disabled="isLoading"
                :max="todayDate"
                min="1900-01-01"
              ></b-form-input>
              <div
                v-for="error of v$.workAfterUK.$each.$response.$errors[index].startDate"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6"
            ><label>26{{ formatNumberToLetter(index) }} - End Date</label></b-col
          >
          <b-col cols="12" lg="6">
            <div class="input-container">
              <b-form-input
                v-model="v$.workAfterUK.$model[index].endDate"
                type="date"
                :class="v$.workAfterUK.$each.$response.$errors[index].endDate.length ? 'is-invalid' : ''"
                :disabled="isLoading"
                :max="todayDate"
                min="1900-01-01"
              ></b-form-input>
              <div
                v-for="error of v$.workAfterUK.$each.$response.$errors[index].endDate"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row class="border-bottom mb-4">
          <b-col cols="12" lg="6"><label>I'm doing this CURRENTLY</label></b-col>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <b-form-select
                v-model="v$.workAfterUK.$model[index].isCurrently"
                :options="currentlyOptions"
                :disabled="isLoading"
              ></b-form-select>
            </div>
          </b-col>
        </b-row> -->
      </template>
    </template>
    <template v-if="workAfterUK.length < 20">
      <b-row>
        <b-col>
          <button class="btn-add" @click.prevent="addOtherNonUKWork">Add Another Job/Work</button>
        </b-col>
      </b-row>
    </template>
  </b-form>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required, requiredIf, helpers } from '@vuelidate/validators';
export default {
  name: 'WorkAfterUkStep',
  inject: ['countryListOptions'],
  props: {
    workAfterUK: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    onSubmit: { type: Function, required: true }
  },
  data() {
    return {
      v$: useVuelidate(),
      todayDate: new Date().toISOString().slice(0, 10),
      afterLeftTheUKOptions: [
        { value: null, text: 'Choose' },
        { value: 'Employed', text: 'Employed' },
        { value: 'Self-Employed', text: 'Self-Employed' },
        { value: 'Career Break', text: 'Career Break' },
        { value: 'Unemployed', text: 'Unemployed' },
        { value: 'Family Carer', text: 'Family Carer' },
        { value: 'Homemaker', text: 'Homemaker' },
        { value: 'Disabled', text: 'Disabled' },
        { value: 'Retired', text: 'Retired' },
        { value: 'Education', text: 'Education' },
        { value: 'Illness', text: 'Illness' }
      ],
      currentlyOptions: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' }
      ]
    };
  },
  validations() {
    return {
      workAfterUK: {
        $each: helpers.forEach({
          afterLeftTheUK: {
            required: helpers.withMessage('Answer required', required)
          },
          nameOfYourEmployerAbroad: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function (value, vm) {
                console.log(value, vm);
                return vm.afterLeftTheUK == 'Employed';
              })
            )
          },
          country: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function (value, vm) {
                console.log(value, vm);
                return vm.afterLeftTheUK == 'Employed';
              })
            )
          },
          street: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function (value, vm) {
                console.log(value, vm);
                return vm.afterLeftTheUK == 'Employed';
              })
            )
          },
          city: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function (value, vm) {
                console.log(value, vm);
                return vm.afterLeftTheUK == 'Employed';
              })
            )
          },
          state: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function (value, vm) {
                return vm.afterLeftTheUK == 'Employed' && this.getCountryStateOptions(vm.country).length;
              })
            )
          },
          postcode: {
            // requiredIf: helpers.withMessage(
            //  "Answer required",
            //  requiredIf(function (value, vm) {
            //    return vm.afterLeftTheUK == 'Employed';
            //  })
            // ),
          },
          startDate: {
            required: helpers.withMessage('Answer required', required),
            minValue: helpers.withMessage('Invalid Date.', (value) => {
              return new Date(value) > new Date('1900-01-01');
            }),
            maxValue: helpers.withMessage('Date not must be after today', (value) => {
              return new Date(value) <= new Date();
            })
          },
          endDate: {
            required: helpers.withMessage('Answer required', required),
            minValue: helpers.withMessage('Date must be before today and after Start Date', (value, vm) => {
              vm.isCurrently = value === this.todayDate ? true : false;
              return new Date(value) <= new Date() && new Date(value) >= new Date(vm.startDate);
            })
          },
          isCurrently: {}
        })
      }
    };
  },
  methods: {
    afterLeftTheUkChange(work) {
      this.$nextTick(() => {
        if (work.afterLeftTheUK != 'Employed') {
          work.nameOfYourEmployerAbroad = null;
          work.country = null;
          work.street = null;
          work.city = null;
          work.state = null;
          work.postcode = null;
        } else work.country = 'IE';
        work.isCurrently = false;
      });
    },
    countryChange(address) {
      address.state = null;
    },
    getCountryOptions() {
      let result = [
        {
          text: 'Choose',
          value: null
        }
      ];
      for (const country in this.countryListOptions) {
        result.push({
          text: this.countryListOptions[country].name,
          value: country.toUpperCase()
        });
      }
      return result;
    },
    getCountryStateOptions(country) {
      let result = [];
      if (country !== null) {
        country = country.toLowerCase();
        for (const stateKey in this.countryListOptions[country].states) {
          result.push({
            text: this.countryListOptions[country].states[stateKey],
            value: stateKey
          });
        }
        if (result?.length) {
          result.unshift({
            text: 'Choose',
            value: null
          });
        }
      }
      return result;
    },
    addOtherNonUKWork() {
      if (this.workAfterUK.length < 20) {
        // eslint-disable-next-line vue/no-mutating-props
        this.workAfterUK.push({
          afterLeftTheUK: null,
          nameOfYourEmployerAbroad: null,
          country: null,
          street: null,
          city: null,
          state: null,
          postcode: null,
          startDate: null,
          endDate: null,
          isCurrently: false
        });
      }
    },
    deleteOtherNonUKWork(index) {
      if (index > -1) {
        // only splice array when item is found
        // eslint-disable-next-line vue/no-mutating-props
        this.workAfterUK.splice(index, 1);
      }
    },
    formatNumberToLetter(num) {
      let result = 'a';
      let arr = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z'
      ];
      if (Number.isInteger(num)) {
        result = arr[num];
      }
      return result;
    },
    capitalizeWords(value) {
      return value
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    submitForm() {
      if (!this.v$.$invalid) {
        this.onSubmit();
      }
    }
  }
};
</script>
