export default [
  {
    value: false,
    text: 'No'
  },
  {
    value: true,
    text: 'Yes'
  }
];
