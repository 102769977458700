<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-form class="p-5" @submit.prevent="submitForm">
    <b-row>
      <b-col cols="12" lg="6">
        <label>1 - Title:</label>
      </b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-select
            v-model="personal.salutation"
            :options="salutationOptions"
            @input="setTouched('salutation')"
            :class="v$.personal.salutation.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
          ></b-form-select>
          <div v-for="error of v$.personal.salutation.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"><label>2 - First Name:</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model.trim="personal.firstName"
            @input="setTouched('firstName')"
            :class="v$.personal.firstName.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            placeholder="Enter Your First Name"
          ></b-form-input>
          <div v-for="error of v$.personal.firstName.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"><label>3 - Surname:</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model="personal.secondName"
            @input="setTouched('secondName')"
            :class="v$.personal.secondName.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            placeholder="Enter Your Surname"
          ></b-form-input>
          <div v-for="error of v$.personal.secondName.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"><label>4 - Date of Birth:</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model="personal.dateOfBirth"
            type="date"
            @input="setTouched('dateOfBirth')"
            :class="v$.personal.dateOfBirth.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            :max="todayDate"
            min="1900-01-01"
          ></b-form-input>
          <div v-for="error of v$.personal.dateOfBirth.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"><label>5 - What is your National Insurance Number?</label></b-col>
      <!-- <b-col cols="12" lg="2">
        <div class="input-container">
          <b-form-select
            v-model="personal.isKnowNationalInsuranceNumber"
            @change="changeIsKnowNationalInsuranceNumber(personal)"
            :options="isKnowNationalInsuranceNumberOptions"
            placeholder="Choose"
            :disabled="isLoading"
          >
          </b-form-select>
          <div
            v-for="error of v$.personal.isKnowNationalInsuranceNumber.$errors"
            class="invalid-feedback"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </div>
      </b-col> -->
      <b-col cols="12" lg="4">
        <div class="input-container">
          <input
            type="text"
            v-mask="'UU 00 00 00 U'"
            placeholder="XX XX XX XX X"
            class="form-control"
            maxlength="13"
            @input="setTouched('personal.insuranceNumber')"
            :class="v$.personal.insuranceNumber.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            v-model="personal.insuranceNumber"
          />
          <div v-for="error of v$.personal.insuranceNumber.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
          <!-- <b-form-text>
            Typically 2 letters followed by 6 numbers and a suffix letter at the
            end. It's on any UK Tax records, old Payslips, HMRC communications
            or your National Insurance card If you don't know, follow these
            instructions to retrieve it -
            <a href="https://xtrapension.com/nino" target="_blank">here</a>
          </b-form-text> -->
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"> <label>6 - Did you work on the Isle of Man or Channel Islands?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-select
            v-model="personal.workOrPayUKNationalInsurance"
            :options="workOrPayUKNationalInsuranceOptions"
            :disabled="isLoading"
          ></b-form-select>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"><label>7 - Have You a Private UK Pension?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-select
            v-model="personal.privateUKPension"
            :options="privateUKPensionOptions"
            @change="pivateUKPensionChange"
            :disabled="isLoading"
          ></b-form-select>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="personal.privateUKPension">
      <b-col cols="12" lg="6"><label>8 - Approx value of UK Pension </label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-input-group append="£">
            <b-form-input
              type="number"
              v-model="personal.approxValue"
              :disabled="isLoading"
              step="0.001"
              min="0"
            ></b-form-input>
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"><label>9 - Your Current Occupation:</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <!-- <model-select :options="occupationOptions" v-model="personal.currentOccupation" placeholder="select item">
          </model-select> -->
          <b-form-input
            v-model="personal.currentOccupation"
            @input="setTouched('currentOccupation')"
            :class="v$.personal.currentOccupation.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            placeholder="Enter"
          ></b-form-input>
          <div v-for="error of v$.personal.currentOccupation.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"><label>10 - Your Current Address Abroad:</label></b-col>
      <b-col cols="12" lg="6">
        <b-row>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Country:</label>
              <b-form-select
                v-model="personal.currentAddressAbroad.country"
                :options="getCountryOptions()"
                @change="countryChange(personal.currentAddressAbroad)"
                @input="setTouched('currentAddressAbroad.country')"
                :class="v$.personal.currentAddressAbroad.country.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
              ></b-form-select>
              <div
                v-for="error of v$.personal.currentAddressAbroad.country.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container" v-if="getCountryStateOptions(personal.currentAddressAbroad.country).length">
              <label>County/State:</label>
              <b-form-select
                v-model="personal.currentAddressAbroad.state"
                @input="setTouched('currentAddressAbroad.state')"
                :class="v$.personal.currentAddressAbroad.state.$error ? 'is-invalid' : ''"
                :options="getCountryStateOptions(personal.currentAddressAbroad.country)"
                :disabled="isLoading"
                placeholder="Enter County/State"
              ></b-form-select>
              <div
                v-for="error of v$.personal.currentAddressAbroad.state.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="12">
            <div class="input-container">
              <label>Number & Street:</label>
              <b-form-input
                v-model="personal.currentAddressAbroad.street"
                @input="setTouched('currentAddressAbroad.street')"
                :class="v$.personal.currentAddressAbroad.street.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
                :formatter="capitalizeWords"
                placeholder="Enter Number & Street"
              ></b-form-input>
              <div
                v-for="error of v$.personal.currentAddressAbroad.street.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Town/District:</label>
              <b-form-input
                v-model="personal.currentAddressAbroad.city"
                @input="setTouched('currentAddressAbroad.city')"
                :class="v$.personal.currentAddressAbroad.city.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
                :formatter="capitalizeWords"
                placeholder="Enter Town/District"
              ></b-form-input>
              <div
                v-for="error of v$.personal.currentAddressAbroad.city.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Postcode</label>
              <b-form-input
                v-model="personal.currentAddressAbroad.postcode"
                @input="setTouched('currentAddressAbroad.postcode')"
                :class="v$.personal.currentAddressAbroad.postcode.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
                placeholder="Enter Postcode"
              ></b-form-input>
              <div
                v-for="error of v$.personal.currentAddressAbroad.postcode.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
              <b-form-text v-if="personal.currentAddressAbroad.country === 'GB'">
                If you don't know, find your postcode here:
                <a href="https://postcodefinder.net/" target="_blank">https://postcodefinder.net/</a><br />
                If you commuted to work from Ireland to Northern Ireland and never had a UK address, please insert the
                Irish address where you would have received your UK Government correspondence
              </b-form-text>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"><label>11 - Your Last UK Home Address before leaving the UK:</label></b-col>
      <b-col cols="12" lg="6">
        <b-row>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Country:</label>
              <b-form-select
                v-model="personal.lastUKHomeAddress.country"
                :options="getCountryOptions()"
                @change="countryChange(personal.lastUKHomeAddress)"
                @input="setTouched('lastUKHomeAddress.country')"
                :class="v$.personal.lastUKHomeAddress.country.$error ? 'is-invalid' : ''"
                :disabled="true"
              ></b-form-select>
              <div
                v-for="error of v$.personal.lastUKHomeAddress.country.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container" v-if="getCountryStateOptions(personal.lastUKHomeAddress.country).length">
              <label>County/State:</label>
              <b-form-select
                v-model="personal.lastUKHomeAddress.state"
                @input="setTouched('lastUKHomeAddress.state')"
                :class="v$.personal.lastUKHomeAddress.state.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
                :options="getCountryStateOptions(personal.lastUKHomeAddress.country)"
                placeholder="Enter County/State"
              ></b-form-select>
              <div
                v-for="error of v$.personal.lastUKHomeAddress.state.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="12">
            <div class="input-container">
              <label>Number & Street:</label>
              <b-form-input
                v-model="personal.lastUKHomeAddress.street"
                @input="setTouched('lastUKHomeAddress.street')"
                :class="v$.personal.lastUKHomeAddress.street.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
                :formatter="capitalizeWords"
                placeholder="Enter Number & Street"
              ></b-form-input>
              <div
                v-for="error of v$.personal.lastUKHomeAddress.street.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Town/District:</label>
              <b-form-input
                v-model="personal.lastUKHomeAddress.city"
                @input="setTouched('lastUKHomeAddress.city')"
                :class="v$.personal.lastUKHomeAddress.city.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
                :formatter="capitalizeWords"
                placeholder="Enter Town/District"
              ></b-form-input>
              <div
                v-for="error of v$.personal.lastUKHomeAddress.city.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Postcode</label>
              <b-form-input
                v-model="personal.lastUKHomeAddress.postcode"
                @input="setTouched('personal.lastUKHomeAddress.postcode')"
                :class="v$.personal.lastUKHomeAddress.postcode.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
                placeholder="Enter Postcode"
              ></b-form-input>
              <div
                v-for="error of v$.personal.lastUKHomeAddress.postcode.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <template v-if="!personal.insuranceNumber">
            <b-col cols="6" lg="4"><label>From </label></b-col>
            <b-col cols="6" lg="8">
              <div class="input-container">
                <b-form-input
                  type="date"
                  v-model="personal.lastUKHomeAddress.fromDate"
                  @input="setTouched('lastUKHomeAddress.fromDate')"
                  :class="v$.personal.lastUKHomeAddress.fromDate.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :max="todayDate"
                  min="1900-01-01"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress.fromDate.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="6" lg="4"><label>To</label></b-col>
            <b-col cols="6" lg="8">
              <div class="input-container">
                <b-form-input
                  type="date"
                  v-model="personal.lastUKHomeAddress.toDate"
                  @input="setTouched('lastUKHomeAddress.toDate')"
                  :class="v$.personal.lastUKHomeAddress.toDate.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :max="todayDate"
                  min="1900-01-01"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress.toDate.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
          </template>
        </b-row>
      </b-col>
    </b-row>
    <!-- <template v-if="!personal.isKnowNationalInsuranceNumber">
      <b-row>
        <b-col cols="12" lg="6"><label>11B - Your 2nd Last UK Home Address:</label></b-col>
        <b-col cols="12" lg="6">
          <b-row>
            <b-col cols="12" lg="6">
              <div class="input-container">
                <label>Country:</label>
                <b-form-select
                  v-model="personal.lastUKHomeAddress2.country"
                  :options="getCountryOptions()"
                  @change="countryChange(personal.lastUKHomeAddress2)"
                  @input="setTouched('lastUKHomeAddress2.country')"
                  :class="v$.personal.lastUKHomeAddress2.country.$error ? 'is-invalid' : ''"
                  :disabled="true"
                ></b-form-select>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress2.country.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="6">
              <div class="input-container" v-if="getCountryStateOptions(personal.lastUKHomeAddress2.country).length">
                <label>County/State:</label>
                <b-form-select
                  v-model="personal.lastUKHomeAddress.state"
                  @input="setTouched('lastUKHomeAddress2.state')"
                  :class="v$.personal.lastUKHomeAddress2.state.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :options="getCountryStateOptions(personal.lastUKHomeAddress2.country)"
                  placeholder="Enter County/State"
                ></b-form-select>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress2.state.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="12">
              <div class="input-container">
                <label>Number & Street:</label>
                <b-form-input
                  v-model="personal.lastUKHomeAddress2.street"
                  @input="setTouched('lastUKHomeAddress2.street')"
                  :class="v$.personal.lastUKHomeAddress2.street.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :formatter="capitalizeWords"
                  placeholder="Enter Number & Street"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress2.street.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="6">
              <div class="input-container">
                <label>Town/District:</label>
                <b-form-input
                  v-model="personal.lastUKHomeAddress2.city"
                  @input="setTouched('lastUKHomeAddress2.city')"
                  :class="v$.personal.lastUKHomeAddress2.city.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :formatter="capitalizeWords"
                  placeholder="Enter Town/District"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress2.city.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="6">
              <div class="input-container">
                <label>Postcode</label>
                <b-form-input
                  v-model="personal.lastUKHomeAddress2.postcode"
                  @input="setTouched('personal.lastUKHomeAddress2.postcode')"
                  :class="v$.personal.lastUKHomeAddress2.postcode.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  placeholder="Enter Postcode"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress2.postcode.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="6" lg="4"><label>From </label></b-col>
            <b-col cols="6" lg="8">
              <div class="input-container">
                <b-form-input
                  type="date"
                  v-model="personal.lastUKHomeAddress2.fromDate"
                  @input="setTouched('lastUKHomeAddress2.fromDate')"
                  :class="v$.personal.lastUKHomeAddress2.fromDate.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :max="todayDate"
                  min="1900-01-01"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress2.fromDate.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="6" lg="4"><label>To</label></b-col>
            <b-col cols="6" lg="8">
              <div class="input-container">
                <b-form-input
                  type="date"
                  v-model="personal.lastUKHomeAddress2.toDate"
                  @input="setTouched('lastUKHomeAddress2.toDate')"
                  :class="v$.personal.lastUKHomeAddress2.toDate.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :max="todayDate"
                  min="1900-01-01"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress2.toDate.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="6"><label>11C - Your 3rd Last UK Home Address:</label></b-col>
        <b-col cols="12" lg="6">
          <b-row>
            <b-col cols="12" lg="6">
              <div class="input-container">
                <label>Country:</label>
                <b-form-select
                  v-model="personal.lastUKHomeAddress3.country"
                  :options="getCountryOptions()"
                  @change="countryChange(personal.lastUKHomeAddress3)"
                  @input="setTouched('lastUKHomeAddress.country')"
                  :class="v$.personal.lastUKHomeAddress3.country.$error ? 'is-invalid' : ''"
                  :disabled="true"
                ></b-form-select>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress3.country.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="6">
              <div class="input-container" v-if="getCountryStateOptions(personal.lastUKHomeAddress3.country).length">
                <label>County/State:</label>
                <b-form-select
                  v-model="personal.lastUKHomeAddress3.state"
                  @input="setTouched('lastUKHomeAddress.state')"
                  :class="v$.personal.lastUKHomeAddress3.state.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :options="getCountryStateOptions(personal.lastUKHomeAddress3.country)"
                  placeholder="Enter County/State"
                ></b-form-select>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress3.state.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="12">
              <div class="input-container">
                <label>Number & Street:</label>
                <b-form-input
                  v-model="personal.lastUKHomeAddress3.street"
                  @input="setTouched('lastUKHomeAddress3.street')"
                  :class="v$.personal.lastUKHomeAddress3.street.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :formatter="capitalizeWords"
                  placeholder="Enter Number & Street"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress3.street.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="6">
              <div class="input-container">
                <label>Town/District:</label>
                <b-form-input
                  v-model="personal.lastUKHomeAddress3.city"
                  @input="setTouched('lastUKHomeAddress3.city')"
                  :class="v$.personal.lastUKHomeAddress3.city.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :formatter="capitalizeWords"
                  placeholder="Enter Town/District"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress3.city.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="6">
              <div class="input-container">
                <label>Postcode</label>
                <b-form-input
                  v-model="personal.lastUKHomeAddress3.postcode"
                  @input="setTouched('personal.lastUKHomeAddress3.postcode')"
                  :class="v$.personal.lastUKHomeAddress3.postcode.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  placeholder="Enter Postcode"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress3.postcode.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="6" lg="4"><label>From </label></b-col>
            <b-col cols="6" lg="8">
              <div class="input-container">
                <b-form-input
                  type="date"
                  v-model="personal.lastUKHomeAddress3.fromDate"
                  @input="setTouched('lastUKHomeAddress3.fromDate')"
                  :class="v$.personal.lastUKHomeAddress3.fromDate.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :max="todayDate"
                  min="1900-01-01"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress3.fromDate.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
            <b-col cols="6" lg="4"><label>To</label></b-col>
            <b-col cols="6" lg="8">
              <div class="input-container">
                <b-form-input
                  type="date"
                  v-model="personal.lastUKHomeAddress3.toDate"
                  @input="setTouched('lastUKHomeAddress3.toDate')"
                  :class="v$.personal.lastUKHomeAddress3.toDate.$error ? 'is-invalid' : ''"
                  :disabled="isLoading"
                  :max="todayDate"
                  min="1900-01-01"
                ></b-form-input>
                <div
                  v-for="error of v$.personal.lastUKHomeAddress3.toDate.$errors"
                  class="invalid-feedback"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template> -->
    <!--
    <b-row>
      <b-col cols="12" lg="6"
        ><label>12 - Previous UK Address (if any) </label></b-col
      >
      <b-col cols="12" lg="6">
        <b-row
          class="border-bottom mb-4"
          v-for="(address, index) in personal.previousUKAddress"
          :key="index"
        >
          <b-col cols="12">
            <div class="delete-icon-container">
              <img
                @click.prevent="deletePreviousUKAddress(index)"
                src="@/assets/delete-icon.svg"
                class="delete-icon"
              />
              <span @click.prevent="deletePreviousUKAddress(index)"
                >Remove This Address</span
              >
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Country:</label>
              <b-form-select
                v-model="v$.personal.previousUKAddress.$model[index].country"
                :options="getCountryOptionsUK()"
                @change="countryChange(address)"
                :class="
                  v$.personal.previousUKAddress.$each.$response.$errors[index]
                    .country.length
                    ? 'is-invalid'
                    : ''
                "
                disabled
              ></b-form-select>
              <div
                v-for="error of v$.personal.previousUKAddress.$each.$response
                  .$errors[index].country"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div
              class="input-container"
              v-if="getCountryStateOptions(address.country).length"
            >
              <label>County/State:</label>
              <b-form-select
                v-model="v$.personal.previousUKAddress.$model[index].state"
                :class="
                  v$.personal.previousUKAddress.$each.$response.$errors[index]
                    .state.length
                    ? 'is-invalid'
                    : ''
                "
                :disabled="isLoading"
                :options="getCountryStateOptions(address.country)"
                placeholder="Enter County/State"
              ></b-form-select>
              <div
                v-for="error of v$.personal.previousUKAddress.$each.$response
                  .$errors[index].state"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Town/District:</label>
              <b-form-input
                v-model="v$.personal.previousUKAddress.$model[index].city"
                :class="
                  v$.personal.previousUKAddress.$each.$response.$errors[index]
                    .city.length
                    ? 'is-invalid'
                    : ''
                "
                :disabled="isLoading"
                placeholder="Enter Town/District"
              ></b-form-input>
              <div
                v-for="error of v$.personal.previousUKAddress.$each.$response
                  .$errors[index].city"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Number & Street:</label>
              <b-form-input
                v-model="v$.personal.previousUKAddress.$model[index].street"
                :class="
                  v$.personal.previousUKAddress.$each.$response.$errors[index]
                    .street.length
                    ? 'is-invalid'
                    : ''
                "
                :disabled="isLoading"
                placeholder="Enter Number & Street"
              ></b-form-input>
              <div
                v-for="error of v$.personal.previousUKAddress.$each.$response
                  .$errors[index].street"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="12">
            <div class="input-container">
              <label>Postcode</label>
              <b-form-input
                v-model="v$.personal.previousUKAddress.$model[index].postcode"
                :class="
                  v$.personal.previousUKAddress.$each.$response.$errors[index]
                    .postcode.length
                    ? 'is-invalid'
                    : ''
                "
                :disabled="isLoading"
                placeholder="Enter Postcode"
              ></b-form-input>
              <div
                v-for="error of v$.personal.previousUKAddress.$each.$response
                  .$errors[index].postcode"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
              <b-form-text>
                If you don't know, find your postcode here:
                <a href="https://postcodefinder.net/" target="_blank"
                  >https://postcodefinder.net/</a
                ><br />
                If you commuted to work from Ireland to Northern Ireland and
                never had a UK address, please insert the Irish address where
                you would have received your UK Government correspondence
              </b-form-text>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <template v-if="personal.previousUKAddress.length < 5">
      <b-row>
        <b-col>
          <button class="btn-add" @click.prevent="addPreviousAddress">
            Add Another Previous UK Address
          </button>
        </b-col>
      </b-row>
    </template>
   -->
  </b-form>
</template>
<script>
import useVuelidate from '@vuelidate/core';
// import { ModelSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';
import { required, minLength, maxLength, requiredIf, helpers } from '@vuelidate/validators';
const insuranceNumberRegex = helpers.regex(/^[A-Z]{2} \d{2} \d{2} \d{2} [A-D]$/);
export default {
  name: 'PersonalStep',
  inject: ['occupationOptions', 'countryListOptions'],
  // components: {
  //   ModelSelect
  // },
  props: {
    personal: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    onSubmit: { type: Function, required: true }
  },
  data() {
    return {
      v$: useVuelidate(),
      todayDate: new Date().toISOString().slice(0, 10),
      salutationOptions: [
        { value: null, text: 'Choose' },
        { value: 'Mr', text: 'Mr' },
        { value: 'Mrs', text: 'Mrs' },
        { value: 'Ms', text: 'Ms' },
        { value: 'Dr', text: 'Dr' },
        { value: 'Prof', text: 'Prof' },
        { value: 'Fr', text: 'Fr' },
        { value: 'Sr', text: 'Sr' }
      ],
      workOrPayUKNationalInsuranceOptions: [
        { value: false, text: 'No' },
        { value: true, text: 'Yes' }
      ],
      privateUKPensionOptions: [
        { value: false, text: 'No' },
        { value: true, text: 'Yes' }
      ],
      isKnowNationalInsuranceNumberOptions: [
        { value: false, text: 'No' },
        { value: true, text: 'Yes' }
      ]
    };
  },
  validations() {
    return {
      personal: {
        salutation: {
          required: helpers.withMessage('Answer required', required)
        },
        firstName: {
          required: helpers.withMessage('Answer required', required),
          minLength: minLength(2),
          maxLength: maxLength(40)
        },
        secondName: {
          required: helpers.withMessage('Answer required', required),
          minLength: minLength(2),
          maxLength: maxLength(80)
        },
        dateOfBirth: {
          required: helpers.withMessage('Answer required', required),
          minValue: helpers.withMessage('Invalid Date.', (value) => {
            return new Date(value) > new Date('1900-01-01');
          }),
          maxValue: helpers.withMessage('Date not must be after today', (value) => {
            return new Date(value) < new Date();
          })
        },
        // isKnowNationalInsuranceNumber: {
        //   required: helpers.withMessage('Answer required', required)
        // },
        insuranceNumber: {
          // requiredIf: helpers.withMessage(
          //   'Answer required',
          //   requiredIf(function () {
          //     return this.personal.isKnowNationalInsuranceNumber;
          //   })
          // ),
          insuranceNumberRegex,
          maxLength: maxLength(13)
        },
        currentOccupation: {
          required: helpers.withMessage('Answer required', required)
        },
        currentAddressAbroad: {
          country: {
            required: helpers.withMessage('Answer required', required)
          },
          city: {
            required: helpers.withMessage('Answer required', required)
          },
          state: {
            requiredIf: requiredIf(function () {
              return this.getCountryStateOptions(this.personal.currentAddressAbroad.country).length ? true : false;
            })
          },
          street: {
            required: helpers.withMessage('Answer required', required)
          },
          postcode: {
            required: helpers.withMessage('Answer required', required)
          }
        },
        lastUKHomeAddress: {
          country: {
            required: helpers.withMessage('Answer required', required)
          },
          city: {
            required: helpers.withMessage('Answer required', required)
          },
          state: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function () {
                return this.getCountryStateOptions(this.personal.lastUKHomeAddress.country).length ? true : false;
              })
            )
          },
          street: {
            required: helpers.withMessage('Answer required', required)
          },
          postcode: {
            required: helpers.withMessage('Answer required', required)
          },
          fromDate: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function () {
                return !this.personal.insuranceNumber?.length ? true : false;
              })
            ),
            minValue: helpers.withMessage('Invalid Date.', (value) => {
              return new Date(value) > new Date('1900-01-01');
            }),
            maxValue: helpers.withMessage('Date not must be after today', (value) => {
              return new Date(value) <= new Date();
            })
          },
          toDate: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function () {
                return !this.personal.insuranceNumber?.length ? true : false;
              })
            ),
            minValue: helpers.withMessage('Date must be before today and after Start Date', (value) => {
              return (
                new Date(value) <= new Date() && new Date(value) >= new Date(this.personal.lastUKHomeAddress.fromDate)
              );
            })
          }
        }

        // lastUKHomeAddress2: {
        //   country: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //   },
        //   city: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //   },
        //   state: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return this.getCountryStateOptions(
        //           this.personal.lastUKHomeAddress2.country
        //         ).length && !this.personal.isKnowNationalInsuranceNumber
        //           ? true
        //           : false;
        //       })
        //     ),
        //   },
        //   street: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //   },
        //   postcode: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //   },
        //   fromDate: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //     minValue: helpers.withMessage('Invalid Date.', (value) => {
        //       return new Date(value) > new Date('1900-01-01');
        //     }),
        //     maxValue: helpers.withMessage('Date not must be after today', (value) => {
        //       return new Date(value) <= new Date();
        //     })
        //   },
        //   toDate: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //     minValue: helpers.withMessage('Date must be before today and after Start Date', (value) => {
        //       return (
        //         new Date(value) <= new Date() && new Date(value) >= new Date(this.personal.lastUKHomeAddress2.fromDate)
        //       );
        //     })
        //   }
        // },
        // lastUKHomeAddress3: {
        //   country: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //   },
        //   city: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //   },
        //   state: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return this.getCountryStateOptions(
        //           this.personal.lastUKHomeAddress2.country
        //         ).length && !this.personal.isKnowNationalInsuranceNumber
        //           ? true
        //           : false;
        //       })
        //     ),
        //   },
        //   street: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //   },
        //   postcode: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //   },
        //   fromDate: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //     minValue: helpers.withMessage('Invalid Date.', (value) => {
        //       return new Date(value) > new Date('1900-01-01');
        //     }),
        //     maxValue: helpers.withMessage('Date not must be after today', (value) => {
        //       return new Date(value) <= new Date();
        //     })
        //   },
        //   toDate: {
        //     requiredIf: helpers.withMessage(
        //       "Answer required",
        //       requiredIf(function () {
        //         return !this.personal.isKnowNationalInsuranceNumber;
        //       })
        //     ),
        //     minValue: helpers.withMessage('Date must be before today and after Start Date', (value) => {
        //       return (
        //         new Date(value) <= new Date() && new Date(value) >= new Date(this.personal.lastUKHomeAddress3.fromDate)
        //       );
        //     })
        //   }
        // }
        // previousUKAddress: {
        //   $each: helpers.forEach({
        //     country: {
        //       requiredIf: helpers.withMessage(
        //         'Answer required',
        //         requiredIf(function () {
        //           return this.personal.previousUKAddress.length;
        //         })
        //       )
        //     },
        //     city: {
        //       requiredIf: helpers.withMessage(
        //         'Answer required',
        //         requiredIf(function () {
        //           return this.personal.previousUKAddress.length;
        //         })
        //       )
        //     },
        //     state: {
        //       requiredIf: helpers.withMessage(
        //         'Answer required',
        //         requiredIf(function (value, vm) {
        //           return this.personal.previousUKAddress.length && this.getCountryStateOptions(vm.country).length;
        //         })
        //       )
        //     },
        //     street: {
        //       requiredIf: helpers.withMessage(
        //         'Answer required',
        //         requiredIf(function () {
        //           return this.personal.previousUKAddress.length;
        //         })
        //       )
        //     },
        //     postcode: {
        //       requiredIf: helpers.withMessage(
        //         'Answer required',
        //         requiredIf(function () {
        //           return this.personal.previousUKAddress.length;
        //         })
        //       )
        //     }
        //   })
        // }
      }
    };
  },
  methods: {
    countryChange(address) {
      address.state = null;
    },
    getCountryOptions() {
      let result = [
        {
          text: 'Choose',
          value: null
        }
      ];
      for (const country in this.countryListOptions) {
        result.push({
          text: this.countryListOptions[country].name,
          value: country.toUpperCase()
        });
      }
      return result;
    },
    getCountryOptionsUK() {
      let result = [
        // {
        //   text: "Choose",
        //   value: null,
        // },
      ];
      for (const country in this.countryListOptions) {
        if (country === 'gb') {
          result.push({
            text: this.countryListOptions[country].name,
            value: country.toUpperCase()
          });
        }
      }
      return result;
    },
    getCountryStateOptions(country) {
      let result = [];
      if (country !== null) {
        country = country.toLowerCase();
        for (const stateKey in this.countryListOptions[country].states) {
          result.push({
            text: this.countryListOptions[country].states[stateKey],
            value: stateKey
          });
        }
        if (result?.length) {
          result.unshift({
            text: 'Choose',
            value: null
          });
        }
      }
      return result;
    },
    // changeIsKnowNationalInsuranceNumber(personal) {
    //   this.$nextTick(() => {
    //     if (personal.isKnowNationalInsuranceNumber) {
    //       personal.lastUKHomeAddress.fromDate = null;
    //       personal.lastUKHomeAddress.toDate = null;
    //       personal.lastUKHomeAddress2 = {
    //         country: 'GB',
    //         street: null,
    //         city: null,
    //         state: null,
    //         postcode: null,
    //         fromDate: null,
    //         toDate: null
    //       };
    //       personal.lastUKHomeAddress3 = {
    //         country: 'GB',
    //         street: null,
    //         city: null,
    //         state: null,
    //         postcode: null,
    //         fromDate: null,
    //         toDate: null
    //       };
    //     } else {
    //       personal.insuranceNumber = '';
    //     }
    //   });
    // },
    pivateUKPensionChange() {
      if (this.personal.privateUKPension === false) {
        // eslint-disable-next-line vue/no-mutating-props
        this.personal.approxValue = 0;
      }
    },
    addPreviousAddress() {
      if (this.personal.previousUKAddress.length < 6) {
        // eslint-disable-next-line vue/no-mutating-props
        this.personal.previousUKAddress.push({
          country: 'GB',
          street: null,
          city: null,
          state: null,
          postcode: null
        });
      }
    },
    capitalizeWords(value) {
      return value
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    deletePreviousUKAddress(index) {
      if (index > -1) {
        // only splice array when item is found
        // eslint-disable-next-line vue/no-mutating-props
        this.personal.previousUKAddress.splice(index, 1);
      }
    },
    setTouched(theModel) {
      if (theModel === 'salutation' || theModel == 'all') {
        this.v$.personal.salutation.$touch();
      }
      if (theModel === 'firstName' || theModel == 'all') {
        this.v$.personal.firstName.$touch();
      }
      if (theModel === 'secondName' || theModel == 'all') {
        this.v$.personal.secondName.$touch();
      }
      if (theModel === 'dateOfBirth' || theModel == 'all') {
        this.v$.personal.dateOfBirth.$touch();
      }
      // if (theModel === 'isKnowNationalInsuranceNumber' || theModel == 'all') {
      //   this.v$.personal.isKnowNationalInsuranceNumber.$touch();
      // }
      if (theModel === 'insuranceNumber' || theModel == 'all') {
        this.v$.personal.insuranceNumber.$touch();
      }
      if (theModel === 'currentOccupation' || theModel == 'all') {
        this.v$.personal.currentOccupation.$touch();
      }
      if (theModel === 'currentAddressAbroad.country' || theModel == 'all') {
        this.v$.personal.currentAddressAbroad.country.$touch();
      }
      if (theModel === 'currentAddressAbroad.city' || theModel == 'all') {
        this.v$.personal.currentAddressAbroad.city.$touch();
      }
      if (theModel === 'currentAddressAbroad.state' || theModel == 'all') {
        this.v$.personal.currentAddressAbroad.state.$touch();
      }
      if (theModel === 'currentAddressAbroad.street' || theModel == 'all') {
        this.v$.personal.currentAddressAbroad.street.$touch();
      }
      if (theModel === 'currentAddressAbroad.postcode' || theModel == 'all') {
        this.v$.personal.currentAddressAbroad.postcode.$touch();
      }
      if (theModel === 'lastUKHomeAddress.country' || theModel == 'all') {
        this.v$.personal.lastUKHomeAddress.country.$touch();
      }
      if (theModel === 'lastUKHomeAddress.city' || theModel == 'all') {
        this.v$.personal.lastUKHomeAddress.city.$touch();
      }
      if (theModel === 'lastUKHomeAddress.state' || theModel == 'all') {
        this.v$.personal.lastUKHomeAddress.state.$touch();
      }
      if (theModel === 'lastUKHomeAddress.street' || theModel == 'all') {
        this.v$.personal.lastUKHomeAddress.street.$touch();
      }
      if (theModel === 'lastUKHomeAddress.postcode' || theModel == 'all') {
        this.v$.personal.lastUKHomeAddress.postcode.$touch();
      }
      if (theModel === 'lastUKHomeAddress.fromDate' || theModel == 'all') {
        this.v$.personal.lastUKHomeAddress.fromDate.$touch();
      }
      if (theModel === 'lastUKHomeAddress.toDate' || theModel == 'all') {
        this.v$.personal.lastUKHomeAddress.toDate.$touch();
      }
      // if (theModel === 'lastUKHomeAddress2.country' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress2.country.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress2.city' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress2.city.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress2.state' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress2.state.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress2.street' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress2.street.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress2.postcode' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress2.postcode.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress2.fromDate' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress2.fromDate.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress2.toDate' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress2.toDate.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress3.country' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress3.country.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress3.city' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress3.city.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress3.state' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress3.state.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress3.street' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress3.street.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress3.postcode' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress3.postcode.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress3.fromDate' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress3.fromDate.$touch();
      // }
      // if (theModel === 'lastUKHomeAddress3.toDate' || theModel == 'all') {
      //   this.v$.personal.lastUKHomeAddress3.toDate.$touch();
      // }
    },
    submitForm() {
      this.setTouched('all');
      if (!this.v$.$invalid) {
        this.onSubmit();
      }
    }
  }
};
</script>
<style>
.ui.search.dropdown > .text {
  color: var(--bs-body-color);
  font-size: 1rem;
  font-weight: 400;
}
.ui.search.selection.dropdown > input.search {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  margin-bottom: 1.5rem;
}
</style>
