<template>
  <b-row>
    <div class="form-wrapper">
      <template v-if="isLoading">
        <div class="spinner-container-main">
          <b-spinner></b-spinner>
        </div>
      </template>
      <template v-else>
        <template v-if="!isAppNotExist">
          <div class="form-wrapper-left">
            <div class="logo-container">
              <img alt="Logo" class="logo" src="@/assets/logo.png" />
            </div>
            <div class="header-text-container">
              <p>All Questions MUST be answered. Your answers are saved AFTER you click ‘NEXT’ on each page.</p>

              <p>
                <i
                  >(You can click the link in the email you got from us to change any answer before you submit your
                  information in section 5)</i
                >
              </p>

              <p>If you do NOT answer all Questions, we will NOT be able to proceed with your application.</p>
              <p class="text-red">
                We MUST get your
                <a class="text-red" href="https://xpen.uk/5ways" target="_blank">National Insurance Number</a> to
                proceed.
              </p>

              <ProgressBar :steps="steps" :current-step="currentStep" :on-change-step="changeCurrentStep" />
            </div>
          </div>
          <div class="form-wrapper-right">
            <div class="header-container">
              <div class="header-title">
                <span class="header-title-step">
                  <template v-if="steps.length">
                    <template v-for="step in steps" :key="step.id">
                      <template v-if="step.active">
                        {{ step.stepNameBreadcrumbs }}
                      </template>
                    </template>
                  </template>
                </span>
              </div>
            </div>

            <div class="form-container">
              <div class="form-container-title px-5 pt-5">
                <template v-if="steps.length">
                  <template v-for="step in steps" :key="step.id">
                    <template v-if="step.active">
                      <span v-if="step.stepNameFull == 'Work After UK' || step.stepNameFull == 'UK Work'">
                        <b> {{ step.stepNameFull }} - </b>
                        <b class="text-red">
                          See What We Need by
                          <a class="text-red" href="https://xpen.uk/history" target="_blank">Clicking Here</a></b
                        >
                      </span>
                      <b v-else>{{ step.stepNameFull }}</b>
                    </template>
                  </template>
                </template>
              </div>
              <PersonalStep
                v-if="currentStep === 1"
                :personal="formData.personal"
                :is-loading="isLoadingStep"
                :on-submit="sendForm"
                ref="PersonalStepComponent"
              />
              <RelationshipStep
                v-if="currentStep === 2"
                :relationship="formData.relationship"
                :is-loading="isLoadingStep"
                :on-submit="sendForm"
                ref="RelationshipStepComponent"
              />
              <UkWorkStep
                v-if="currentStep === 3"
                :ukwork="formData.ukwork"
                :is-loading="isLoadingStep"
                :on-submit="sendForm"
                ref="EmploymentStepComponent"
              />
              <WorkAfterUkStep
                v-if="currentStep === 4"
                :workAfterUK="formData.workAfterUK"
                :is-loading="isLoadingStep"
                :on-submit="sendForm"
                ref="DatesStepComponent"
              />
              <DocumentsStep
                v-if="currentStep === 5"
                :personal="formData.personal"
                :documents="formData.documents"
                :agent-authorisation="agentAuthorisation"
                :is-loading="isLoadingStep"
                :on-submit="sendForm"
                ref="DocumentsStepComponent"
              />
              <div v-if="isLoadingStep" class="spinner-container mt-3 mb-3 p-2">
                <b-spinner></b-spinner>
              </div>
              <div class="form-btns-container">
                <div class="m-3">
                  <div v-if="currentStep > 1">
                    <button class="btn-main" :disabled="isLoadingStep" @click="goToPreviosStep($event)">
                      Previous
                    </button>
                  </div>
                </div>
                <div class="m-3">
                  <div v-if="currentStep < 5">
                    <button class="btn-main" @click="submitForm($event)" :disabled="isLoadingStep">Next</button>
                  </div>
                  <div v-if="currentStep === 5">
                    <button
                      class="btn-main"
                      @click="submitForm($event)"
                      :disabled="isLoadingStep || !agentAuthorisation.basic || !agentAuthorisation.termsAndConditions"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="invalid-container">
            <div class="flex-center">
              <img alt="Logo" class="p-3" src="@/assets/logo.png" />
              <h3 class="text-center">Your Application has already been Submitted.</h3>
              <h3 class="text-center">Thank You!</h3>
              <p class="p-4 text-center">
                Our team is now reviewing it and should be back to you in a few days for your approval and to proceed.
              </p>
            </div>
          </div>
        </template>
      </template>
    </div>
  </b-row>
</template>
<script>
// API
import FormService from '@/services/form-service/form.service';
import { toast } from 'vue3-toastify';
// Components
import ProgressBar from '@/components/form-layouts/ProgressBar/ProgressBar.vue';
import PersonalStep from '@/components/form-layouts/PersonalStep/PersonalStep.vue';
import RelationshipStep from '@/components/form-layouts/RelationshipStep/RelationshipStep.vue';
import UkWorkStep from '@/components/form-layouts/UkWorkStep/UkWorkStep.vue';
import WorkAfterUkStep from '@/components/form-layouts/WorkAfterUkStep/WorkAfterUkStep.vue';
import DocumentsStep from '@/components/form-layouts/DocumentsStep/DocumentsStep.vue';
import './FormView.scss';
/* eslint-disable */
export default {
  name: 'FormView',
  components: {
    ProgressBar,
    PersonalStep,
    RelationshipStep,
    UkWorkStep,
    WorkAfterUkStep,
    DocumentsStep
  },
  data() {
    return {
      isLoading: true,
      isLoadingStep: false,
      isAppNotExist: true,
      formId: null,
      currentStep: 1,
      steps: [
        {
          id: 1,
          active: false,
          stepName: 'Personal',
          stepNameFull: 'Personal Details',
          stepNameBreadcrumbs: 'Personal - Details & Addresses',
          stepQuestionText: '(1 - 12)'
        },
        {
          id: 2,
          active: false,
          stepName: 'Relationship',
          stepNameFull: 'Relationship Details',
          stepNameBreadcrumbs: 'Relationship - Details & Dates',
          stepQuestionText: '(13 - 18)'
        },
        {
          id: 3,
          active: false,
          stepName: 'UK Work',
          stepNameFull: 'UK Work',
          stepNameBreadcrumbs: 'Your Last UK Work - Details & Dates',
          stepQuestionText: '(19 - 25)'
        },
        {
          id: 4,
          active: false,
          stepName: 'Work After UK',
          stepNameFull: 'Work After UK',
          stepNameBreadcrumbs: 'Work After You Left The UK - Details & Dates',
          stepQuestionText: '(26 - 28)'
        },
        {
          id: 5,
          active: false,
          stepName: 'Document(s)',
          stepNameFull: 'Important Document Needed',
          stepNameBreadcrumbs: 'Supporting Document(s) & Finish!',
          stepQuestionText: ''
        }
      ],
      formData: {
        personal: {
          salutation: null,
          firstName: null,
          secondName: null,
          dateOfBirth: null,
          isKnowNationalInsuranceNumber: false,
          insuranceNumber: null,
          workOrPayUKNationalInsurance: false,
          privateUKPension: false,
          approxValue: 0,
          currentOccupation: null,
          currentAddressAbroad: {
            country: 'IE',
            street: null,
            city: null,
            state: null,
            postcode: null
          },
          lastUKHomeAddress: {
            country: 'GB',
            street: null,
            city: null,
            state: null,
            postcode: null,
            fromDate: null,
            toDate: null
          },
          lastUKHomeAddress2: {
            country: 'GB',
            street: null,
            city: null,
            state: null,
            postcode: null,
            fromDate: null,
            toDate: null
          },
          lastUKHomeAddress3: {
            country: 'GB',
            street: null,
            city: null,
            state: null,
            postcode: null,
            fromDate: null,
            toDate: null
          },
          previousUKAddress: [
            // {
            //   country: null,
            //   street: null,
            //   city: null,
            //   state: null,
            //   postcode: null,
            // },
          ]
        },
        relationship: {
          single: false,
          dateOfMarriedOrPartnership: null,
          changeYouName: false,
          fullMaindenOrPreviousName: null,
          divorcedOrwidowed: false,
          exactDate: null
        },
        ukwork: {
          dateOfLeavingLastUKEmployment: null,
          yearsWorkInUK: 0,
          workInUKBeforeLeaving: null,
          nameOfLastUKEmployer: null,
          lastUKEmployerAddress: {
            country: 'GB',
            street: null,
            city: null,
            state: null,
            postcode: null
          },
          startDateOfLastUKEmployer: null,
          endDateOfLastUKEmployer: null
        },
        workAfterUK: [
          {
            afterLeftTheUK: null,
            nameOfYourEmployerAbroad: null,
            country: null,
            street: null,
            city: null,
            state: null,
            postcode: null,
            startDate: null,
            endDate: null
          }
        ],
        documents: {
          applicationId: null,
          applicationName: null,
          statements: null
        }
      },
      agentAuthorisation: {
        termsAndConditions: false,
        basic: false
        // authorise: false,
      }
    };
  },
  mounted() {
    console.log('Transaction ID', this.$route.params.id);
    if (this.$route?.params?.id) {
      this.formId = this.$route?.params?.id;
      this.initForm();
    }
  },
  methods: {
    async initForm() {
      this.showSpinner();
      try {
        const { status, formData, currentStep } = await FormService.getFormData(this.formId);
        console.log('Init Form', status, formData, currentStep);
        if (status === 'Success') {
          this.isAppNotExist = false;
          this.formData = { ...this.formData, ...formData };
          this.getCurrentStepNumber(currentStep ?? 1);
        } else {
          toast.warning(status ?? 'Oops, Something Went Wrong');
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message ?? 'Oops, Something Went Wrong');
      } finally {
        this.hideSpinner();
      }
    },
    getCurrentStepNumber(stepName) {
      const stepNumbers = {
        'Personal Details': 1,
        Relationship: 2,
        'UK Work': 3,
        'Work After UK': 4,
        Documents: 5
      };
      this.currentStep = stepNumbers[stepName] || 1;
      this.$nextTick(() => {
        this.setCurrentStep();
      });
    },
    getCurrentStepName() {
      const stepNames = {
        1: 'Personal Details',
        2: 'Relationship',
        3: 'UK Work',
        4: 'Work After UK',
        5: 'Documents'
      };
      return stepNames[this.currentStep] || 'Personal Details';
    },
    getFormDataForSendForm() {
      switch (this.currentStep) {
        case 1:
          return this.formData.personal;
        case 2:
          return this.formData.relationship;
        case 3:
          return this.formData.ukwork;
        case 4:
          return { workAfterUK: this.formData.workAfterUK };
        case 5:
          return {
            ...this.formData.documents,
            firstName: this.formData.personal.firstName,
            lastName: this.formData.personal.secondName
          };
        default:
          return this.formData.personal;
      }
    },
    async submitForm(event) {
      event.preventDefault();
      event.stopPropagation();
      try {
        const components = [
          this.$refs.PersonalStepComponent,
          this.$refs.RelationshipStepComponent,
          this.$refs.EmploymentStepComponent,
          this.$refs.DatesStepComponent,
          this.$refs.DocumentsStepComponent
        ];
        if (this.currentStep >= 1 && this.currentStep <= components.length) {
          await components[this.currentStep - 1].submitForm();
        } else {
          console.log('Not found!');
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message ?? 'Oops, Something Went Wrong');
      }
    },
    async sendForm() {
      this.showStepSpinner();
      try {
        const { status, isComplete } = await FormService.sendFormData(
          {
            ...this.getFormDataForSendForm(),
            stepName: this.getCurrentStepName()
          },
          this.formId
        );
        if (status === 'Success') {
          if (isComplete === true) {
            window.open('https://xtrapension.com/thank-you', '_self');
          } else {
            this.goToNextStep();
          }
        } else {
          toast.warning(status ?? 'Oops, Something Went Wrong');
        }
      } catch (error) {
        toast.error(error.message ?? 'Oops, Something Went Wrong');
        console.log(error);
      } finally {
        this.hideStepSpinner();
      }
    },
    goToNextStep() {
      if (this.currentStep < this.steps.length) {
        this.currentStep++;
        this.$nextTick(() => {
          this.setCurrentStep();
        });
      }
    },
    goToPreviosStep(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.currentStep <= this.steps.length && this.currentStep > 0) {
        this.currentStep--;
        this.$nextTick(() => {
          this.setCurrentStep();
        });
      }
    },
    setCurrentStep() {
      this.steps = this.steps.map((step) => ({
        id: step.id,
        active: this.currentStep === step.id ? true : false,
        stepQuestionText: step.stepQuestionText,
        stepName: step.stepName,
        stepNameFull: step.stepNameFull,
        stepNameBreadcrumbs: step.stepNameBreadcrumbs
      }));
    },
    changeCurrentStep(step = 1) {
      try {
        this.currentStep = Number(step);
        this.$nextTick(() => {
          this.setCurrentStep();
        });
      } catch (error) {
        toast.error(error.message ?? 'Oops, Something Went Wrong');
        console.log(error);
      }
    },
    showSpinner() {
      this.isLoading = true;
    },
    hideSpinner() {
      this.isLoading = false;
    },
    showStepSpinner() {
      this.isLoadingStep = true;
    },
    hideStepSpinner() {
      this.isLoadingStep = false;
    }
  }
};
</script>
