import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView/HomeView.vue';
import FormView from '@/views/FormView/FormView.vue';
import MobileFormView from '@/views/MobileFormView/MobileFormView.vue';
import PaymentView from '@/views/PaymentView/PaymentView.vue';
import FullFormView from '@/views/FullFormView/FullFormView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/phone',
    name: 'payWithMoto',
    component: HomeView
  },
  {
    path: '/now',
    name: 'now',
    component: HomeView
  },
  {
    path: '/form/:id',
    name: 'form',
    component: FormView
  },
  {
    path: '/mobile-form/:id',
    name: 'mobileForm',
    component: MobileFormView
  },
  {
    path: '/full-form/:id',
    name: 'full-form',
    component: FullFormView
  },
  {
    path: '/pay/:id',
    name: 'pay',
    component: PaymentView
  },
  {
    path: '/phone/pay/:id',
    name: 'phonePay',
    component: PaymentView
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
